import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const Profile = () => {
  const name = localStorage.getItem("name");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMoreVertClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreVertClose = () => {
    setAnchorEl(null);
  };

  // New Group Logic
  const handleNewGroup = () => {};
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "90%",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <h4>{name}</h4>
        <div>
          <IconButton
            color="primary"
            onClick={handleMoreVertClick}
            aria-controls="more-vert-menu"
            aria-haspopup="true"
          >
            {/* <MoreVertIcon /> */}
          </IconButton>
          <Menu
            id="more-vert-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMoreVertClose}
            style={{ marginLeft: "-80px" }}
          >
            <MenuItem onClick={handleNewGroup}>New Group</MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Profile;
