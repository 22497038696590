// RealTime Database
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// Production

const firebaseConfig = {
  apiKey: "AIzaSyBTWE5Jy66izP6OnzhSyPeCOL1qAz4u57g",
  authDomain: "capital-job-links.firebaseapp.com",
  databaseURL: "https://capital-job-links-default-rtdb.firebaseio.com",
  projectId: "capital-job-links",
  storageBucket: "capital-job-links.appspot.com",
  messagingSenderId: "586175177131",
  appId: "1:586175177131:android:7ab9fbd36f61bae2e932ee"
};

// Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyDZJlzj9K-2OAirMjFJIyBMnrtV0sjkmWA",
//   authDomain: "capital-job-dev-8949d.firebaseapp.com",
//   databaseURL: "https://capital-job-dev-8949d-default-rtdb.firebaseio.com",
//   projectId: "capital-job-dev-8949d",
//   storageBucket: "capital-job-dev-8949d.appspot.com",
//   messagingSenderId: "446901590630",
//   appId: "1:446901590630:android:ea8e71bc0c15680199aba7"
// };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();
const db = firebaseApp.database();

export { auth, storage, db };

