import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import React, { useEffect } from "react";
import appLogo from "../../assets/Capital_Job_Link/Images/appLogo.png";
import style from "./Signin.module.css";
import styles from "../Forgot_Password/forgotPassword.module.css";
import { useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log("Base_Url", BASE_URL);

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const { email, password } = userData;
  const navigate = useNavigate();
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
  });
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingNewPassword, setLoadingNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const { newPassword } = passwordData;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const [resendCount, setResendCount] = useState(
    parseInt(localStorage.getItem("resendCount")) || 0
  );
  const [forgotPasswordEmailError, setforgotPasswordEmailError] =
    useState(false);
  const [forgotEmailError, setforgotEmailError] = useState(false);
  const [signinEmailError, setSigninEmailError] = useState(false);
  const [OTPDialogOpen, setOTPDialogOpen] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i;
      const inputValue = e.target.value;

      if (!emailRegex.test(inputValue)) {
        setSigninEmailError(true);
      } else {
        setSigninEmailError(false);
      }

      setUserData((prevData) => ({ ...prevData, inputValue }));
    }
  };
 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError(false);
    setPasswordError(false);

    if (userData.email === "") {
      setEmailError(true);
      return;
    }
    if (userData.password === "") {
      setPasswordError(true);
      return;
    }
    if (signinEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/admin_login`, userData);
      const Email = response?.data?.data?.email;
      const Name = response?.data?.data?.name;
      const token = response?.data?.data?.accessToken;
      let id = response?.data?.data?.id;
      localStorage.setItem("email", Email);
      localStorage.setItem("name", Name);
      localStorage.setItem("token", token);
      localStorage.setItem("id", id);
      // console.log(response);
      setDialogMessage(response?.data?.message);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
        navigate("/");
      }, 2000);
      setUserData({
        email: "",
        password: "",
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      setDialogMessage(errorMessage);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);

      setUserData({
        email: "",
        password: "",
      });
    } finally {
      setLoading(false);
    }
  };
  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleOpenForgotPasswordDialog = () => {
    setForgotPasswordDialogOpen(true);
  };

  const handleCloseForgotPasswordDialog = () => {
    setForgotPasswordDialogOpen(false);
    setForgotPasswordEmail(" ");
  };

  const handleForgotPasswordEmailChange = (e) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i;
    const inputValue = e.target.value;

    if (!emailRegex.test(inputValue)) {
      setforgotPasswordEmailError(true);
    } else {
      setforgotPasswordEmailError(false);
    }

    setForgotPasswordEmail(inputValue);
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i;
      if (!emailRegex.test(forgotPasswordEmail)) {
        setDialogMessage("Please Enter Valid Email");
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        return;
      }
      if (forgotPasswordEmail === "") {
        setforgotEmailError(true);
        return;
      }
      localStorage.setItem("forgotPasswordEmail", forgotPasswordEmail);
      setLoadingPassword(true);
      const response = await axios.post(
        `${BASE_URL}/generateOtpForgotPasswordAdmin`,
        { email: forgotPasswordEmail }
      );
      // console.log("forgotRes", response);
      setDisableResend(true);
      setTimeout(() => {
        setDisableResend(false);
      }, 60000);
      const id = response?.data?.data?.id;
      localStorage.setItem("id", id);
      // const otpSecret = response?.data?.data?.otpSecret;
      // localStorage.setItem("otpSecret", otpSecret);
      setDialogMessage(response?.data?.message);
      setForgotPasswordEmail(" ");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
        handleOpenOTPDialog();
      }, 3000);
    } catch (error) {
      console.log(error);
      setDialogMessage(error.response?.data?.message);
      setForgotPasswordEmail(" ");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      setLoadingPassword(false);
    } finally {
      setLoadingPassword(false);
    }
  };
  const id = localStorage.getItem("id");

  const handleOpenOTPDialog = () => {
    setOTPDialogOpen(true);
    handleCloseForgotPasswordDialog();
  };
  const handleCloseOTPDialog = () => {
    setOTPDialogOpen(false);
    setOtp("");
  };

  const handleOTPSubmit = async () => {
    try {
      if (otp === "") {
        setOtpError(true);
        return;
      }
      setLoadingOtp(true);
      const response = await axios.post(
        `${BASE_URL}/OtpVerificationAdmin/` + id,
        {
          otp: otp,
        }
      );
      const otpSecret = response?.data?.data?.otpSecret;
      localStorage.setItem("otpSecret", otpSecret);
      setDialogMessage(response?.data?.message);
      localStorage.removeItem("forgotPasswordEmail");
      localStorage.removeItem("resendCount");

      setOtp(" ");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
        handleOpenNewPasswordDialog();
      }, 3000);
    } catch (error) {
      console.log(error);
      setDialogMessage(error?.response?.data?.message);

      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      setOtp(" ");
    } finally {
      setLoadingOtp(false);
    }
  };

  const handleOpenNewPasswordDialog = () => {
    setNewPasswordDialogOpen(true);
    handleCloseOTPDialog(); // Close the "Forgot Password" dialog
  };
  const handleCloseNewPasswordDialog = () => {
    setNewPasswordDialogOpen(false);
    localStorage.removeItem("id");
    localStorage.removeItem("otpSecret");
    setPasswordData({
      newPassword: "",
    });
    setConfirmPassword("");
  };

  const handleNewPasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
    if (name === "newPassword") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setPasswordData((prevData) => ({ ...prevData, isValidPassword }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(newPassword === confirmPasswordValue);
  };

  const handleNewPasswordSubmit = async () => {
    try {
      if (passwordData.newPassword === "") {
        setNewPasswordError(true);
        return;
      }
      if (confirmPassword === "") {
        setConfirmPasswordError(true);
        return;
      }
      if (!passwordData.isValidPassword) {
        setDialogMessage(
          "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, at least one numeric digit (0-9), and at least one special character."
        );
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        return;
      }
      if (!passwordsMatch || newPassword !== confirmPassword) {
        setDialogMessage("Passwords Don't Match");
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        return;
      }
      setLoadingNewPassword(true);
      const otpSecret = localStorage.getItem("otpSecret");
      const response = await axios.post(`${BASE_URL}/newPasswordAdmin/` + id, {
        newPassword: passwordData.newPassword,
        otpSecret: otpSecret,
      });
      setDialogMessage(response?.data?.message);
      setPasswordData({
        newPassword: "",
      });
      setConfirmPassword("");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);

        localStorage.removeItem("id");
        localStorage.removeItem("otpSecret");
        handleCloseNewPasswordDialog();
      }, 2000);
    } catch (error) {
      console.log(error);
      setDialogMessage(error?.response?.data?.message);
      // setPasswordData({
      //   newPassword: " ",
      //   confirmPassword: "",
      // });
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
    } finally {
      setLoadingNewPassword(false);
    }
  };

  const handleResendOTP = async () => {
    if (resendCount < 3) {
      const forgotPasswordEmail = localStorage.getItem("forgotPasswordEmail");
      // localStorage.removeItem("otpSecret");
      setDisableResend(true);
      setResendCount(resendCount + 1);
      setCountdown(60); // Reset the countdown to 60 seconds
      localStorage.setItem("resendCount", resendCount);

      try {
        const response = await axios.post(
          `${BASE_URL}/generateOtpForgotPasswordAdmin`,
          { email: forgotPasswordEmail }
        );
        // const otpSecret = response?.data?.data?.otpSecret;
        // localStorage.setItem("otpSecret", otpSecret);
        setDialogMessage(response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setDialogMessage(error.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
      }
    } else {
      setDialogMessage(
        "You have exceeded the maximum OTP resend attempts. Please try again after 30 minutes."
      );
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
    }
  };

  setTimeout(() => {
    localStorage.removeItem("resendCount");
  }, 1800000);

  useEffect(() => {
    let interval;
    const startCountdown = () => {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(interval);
            setDisableResend(false); // Re-enable the button when countdown reaches 0
            return 0;
          }
        });
      }, 1000);
    };

    // Start the countdown when the component mounts
    if (disableResend) {
      startCountdown();
    }

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [disableResend]);

  return (
    <Box>
      <Box className={style.signinContainer}>
        <Box className={style.logoContainer}>
          <img className={style.appLogo} src={appLogo} alt="logo" />
        </Box>
        <Box className={style.signinContainer_leftSection}>
          <Box>
            <Box className={style.helloText}>
              <p style={{ marginBottom: "20px" }}>Hello again!</p>
              <p>Welcome Back</p>
            </Box>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box className={style.emailContainer}>
              <TextField
                className={style.emailInput}
                label="Email"
                onChange={handleChange}
                required
                variant="outlined"
                // color="secondary"
                type="email"
                sx={{
                  mt: "-12px",
                  mb: 2,
                  backgroundColor: "rgb(250, 245, 245)",
                }}
                fullWidth
                name="email"
                value={email}
                error={emailError || signinEmailError}
                helperText={
                  signinEmailError ? "Please enter a valid email address" : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={style.passwordInput}
                label="Password"
                onChange={handleChange}
                required
                variant="outlined"
                // color="secondary"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                error={passwordError}
                fullWidth
                sx={{ mb: 2, backgroundColor: "rgb(250, 245, 245)" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: "flex", margin: "auto" }}>
                <Button
                
                  type="submit"
                  disabled={loading}
                  className={style.signinButton}
                >
                  {loading ? (
                    <CircularProgress size={24} /> // Show loader when loading is true
                  ) : (
                    " Sign In"
                  )}
                </Button>
              </Box>

              <Box style={{ marginBottom: "50px" }}>
                <p
                  className={style.forgotPasswordLink}
                  onClick={handleOpenForgotPasswordDialog}
                >
                  Forgot Password?
                </p>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Dialog
        open={forgotPasswordDialogOpen}
        onClose={handleCloseForgotPasswordDialog}
        // style={{ marginLeft: "650px" }}
      >
        <DialogTitle
          fontWeight="bold"
          className={styles.forgotPasswordHeadTitle}
        >
          Find Your Account
        </DialogTitle>
        <Divider />
        <DialogTitle className={styles.forgotPasswordTitle}>
          Please enter your e-mail address to search for your account.
        </DialogTitle>
        <DialogContent className={styles.forgotPasswordDialogBox}>
          <TextField
            className={styles.forgotPasswordInput}
            style={{ marginTop: "15px" }}
            label="Email"
            onChange={handleForgotPasswordEmailChange}
            required
            variant="outlined"
            type="email"
            fullWidth
            name="email"
            value={forgotPasswordEmail}
            error={forgotEmailError || forgotPasswordEmailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              forgotPasswordEmailError
                ? "Please enter a valid email address"
                : ""
            }
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            className={styles.forgotPasswordCancelBtn}
            onClick={handleCloseForgotPasswordDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.forgotPasswordContinueBtn}
            onClick={handleForgotPasswordSubmit}
            color="primary"
            disabled={loadingPassword}
          >
            {loadingPassword ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Continue"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* OTP verification Dialog */}

      <Dialog
        open={OTPDialogOpen}
        onClose={handleCloseOTPDialog}
        // style={{ marginLeft: "650px" }}
      >
        <DialogTitle fontWeight="bold" className={styles.otpHeadTitle}>
          Enter security code
        </DialogTitle>
        <Divider />
        <DialogTitle className={styles.otpTitle}>
          Please check your e-mail for a message with your code. Your code is 4
          numbers long.
        </DialogTitle>
        <DialogContent className={styles.otpDialogBox}>
          <TextField
            className={styles.otpInput} // Adjust the max width as needed
            label="Enter Code"
            onChange={(e) => setOtp(e.target.value)}
            required
            variant="outlined"
            type="text"
            fullWidth
            error={otpError}
          />
        </DialogContent>

        <Divider />

        <DialogActions style={{ display: "flex", alignItems: "center" }}>
          <span
            // style={{ color: "brown", marginRight: "2vw", marginLeft: "1.3vw",fontSize:"1vw" }}
            className={styles.timer}
          >
            {disableResend ? `${countdown} secs` : ""}
          </span>
          <Button
            disabled={disableResend}
            onClick={handleResendOTP}
            color="primary"
            className={styles.otpResendBtn}
            style={{
              // width:"20vw",
              // height:"8vw",
              // marginRight: "1.3vw",
              backgroundColor: disableResend
                ? "rgb(207, 207, 214)"
                : "rgb(0, 100, 255)", // Change background color when disabled
              color: disableResend ? "white" : "white", // Change text color when disabled
              // fontWeight: "bold",
              // fontSize:"2vw"
            }}
          >
            Resend
          </Button>

          <Button
            // style={{
            //   backgroundColor: "rgb(207, 207, 214)",
            //   color: "rgb(26, 25, 25)",
            //   marginRight: "1.3vw",
            //   fontWeight: "bold",
            //   fontSize:"1vw"
            // }}
            className={styles.otpCancelBtn}
            onClick={handleCloseOTPDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.otpContinueBtn}
            onClick={handleOTPSubmit}
            color="primary"
            disabled={loadingOtp}
          >
            {loadingOtp ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Continue"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Password Dialog */}
      <Dialog
        open={newPasswordDialogOpen}
        onClose={handleCloseNewPasswordDialog}
        // style={{ marginLeft: "650px" }}
      >
        <DialogTitle className={styles.newPasswordHeadTitle}>
          New Password
        </DialogTitle>
        <Divider />
        {/* <DialogTitle className={styles.newPasswordTitle}>
          Choose a new password for updating your account security.
        </DialogTitle> */}

        <DialogContent className={styles.newPasswordDialogBox}>
          <TextField
            className={styles.newPasswordInput}
            autoFocus
            label="New Password"
            onChange={handleNewPasswordChange}
            required
            variant="outlined"
            margin="dense"
            type={showNewPassword ? "text" : "password"}
            fullWidth
            value={newPassword}
            name="newPassword"
            error={
              newPasswordError ||
              (newPassword &&
                newPassword.length > 0 &&
                !passwordData.isValidPassword)
            }
            helperText={
              newPassword &&
              newPassword.length > 0 &&
              !passwordData.isValidPassword
                ? "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, at least one numeric digit (0-9), and at least one special character."
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleNewPasswordVisibility}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
      
          <TextField
            className={styles.confirmPasswordInput}
            label="Confirm Password"
            onChange={handleConfirmPasswordChange}
            required
            variant="outlined"
            margin="dense"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            value={confirmPassword}
            // name="confirmPassword"
            error={confirmPasswordError || !passwordsMatch}
            helperText={!passwordsMatch && "Passwords don't match"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            className={styles.newPasswordCancelBtn}
            onClick={handleCloseNewPasswordDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={styles.newPasswordContinueBtn}
            onClick={handleNewPasswordSubmit}
            color="primary"
            disabled={loadingNewPassword}
          >
            {loadingNewPassword ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Continue"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for alert message of Signin */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SignIn;
