import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import SignIn from "./Pages/SignIn/SignIn";
import PrivateRoute from "./Components/PrivateRoutes";
import DisplayUsers from "./Pages/UsersPanel/DisplayUsers";
import DisplayAppUsers from "./Pages/UsersPanel/AppUsers";
import DisplayNotifications from "./Pages/Notifications/DisplayNotifications";
import ChatComponent from "./Chat/ChatComponent";

function App() {
  return (
    <Box>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          exact
          element={
            <PrivateRoute>
              <DisplayUsers />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/appUsers"
          exact
          element={
            <PrivateRoute>
              <DisplayAppUsers />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/notifications"
          exact
          element={
            <PrivateRoute>
              <DisplayNotifications />
            </PrivateRoute>
          }
        ></Route>

        <Route path="/signin" element={<SignIn />} />

        {/* Chat Route */}
        <Route path="/chat">
          <Route index element={<ChatComponent />} />
        </Route>
      </Routes>
    </Box>
  );
}

export default App;
