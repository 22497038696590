import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../appStore";
import HomeIcon from "@mui/icons-material/Home";
import NotificationIcon from "@mui/icons-material/Notifications";
import ChatIcon from "@mui/icons-material/Chat";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// Mobile Screen

const drawerWidth1 = 58;

const openedMixin1 = (theme) => ({
  width: drawerWidth1,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin1 = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader1 = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer1 = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth1,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin1(theme),
    "& .MuiDrawer-paper": openedMixin1(theme),
  }),
  ...(!open && {
    ...closedMixin1(theme),
    "& .MuiDrawer-paper": closedMixin1(theme),
  }),
}));

export default function Sidenav() {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const open = useAppStore((state) => state.dopen);
  const isDesktop = useMediaQuery("(min-width: 960px)");
  return (
   <Box>
    {isDesktop ?( <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        {/* <IconButton onClick={() => setOpen(!open)}>
         <MenuIcon />
        </IconButton> */}
      </DrawerHeader>
      <List>
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/users");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

        {/* App User */}
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/appUsers");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PhoneAndroidIcon />
            </ListItemIcon>
            <ListItemText primary="App Users" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

         {/* Notifications */}
         <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/notifications");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <NotificationIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

         {/* Chat */}
         <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/chat");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Chat" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>



      </List>
    </Drawer>):( <Drawer1 variant="permanent" open={open}>
      <DrawerHeader1>
        {/* <IconButton onClick={() => setOpen(!open)}>
         <MenuIcon />
        </IconButton> */}
      </DrawerHeader1>
      <List>
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <HomeIcon />
            </ListItemIcon>
            {/* <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} /> */}
          </ListItemButton>
        </ListItem>

        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/users");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PeopleIcon />
            </ListItemIcon>
            {/* <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} /> */}
          </ListItemButton>
        </ListItem>

        {/* App User */}
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/appUsers");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <PhoneAndroidIcon />
            </ListItemIcon>
            {/* <ListItemText primary="App Users" sx={{ opacity: open ? 1 : 0 }} /> */}
          </ListItemButton>
        </ListItem>

        {/* Notifications */}
        <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/notifications");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <NotificationIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

         {/* Chat */}
         <ListItem
          disablePadding
          sx={{ display: "block" }}
          onClick={() => {
            navigate("/chat");
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ChatIcon />
            </ListItemIcon>
            <ListItemText primary="Chat" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>

      </List>
    </Drawer1>)}
   </Box>
  );
}
