import React from "react";
import { CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <div className="loader" style={{ marginLeft: "570px", marginTop: "230px" }}>
      <CircularProgress />
    </div>
  );
};

export default Loader;
