import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import React, { useState, useEffect } from "react";
import PopupForm from "./PopupForm";
import { generatePageNumbers } from "../../Components/Pagination/Pagination";
import axios from "axios";
import Navbar from "../../Components/Navbar/Navbar";
import Sidenav from "../../Components/Navbar/SideNav";
import style from "./appUsers.module.css";
import Loader from "../../Components/LoaderComponent";
const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log("UserBaseUrl", BASE_URL);

const DisplayAppUsers = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [page, setPage] = useState(0);
  // const rowsPerPage = Math.ceil(displayedUsers.length / 10);
  const rowsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  // const [verifiedUsers, setVerifiedUsers] = useState([]);
  const isDesktop = useMediaQuery("(min-width: 667px)");
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [deleteConfirmationUser, setDeleteConfirmationUser] = useState(null);
  const [isVerifyConfirmationOpen, setIsVerifyConfirmationOpen] =
    useState(false);
  const [verifyConfirmationUser, setVerifyConfirmationUser] = useState(null);
  const [isStatusConfirmationOpen, setIsStatusConfirmationOpen] =
    useState(false);
  const [statusConfirmationUser, setStatusConfirmationUser] = useState(null);
  const [isSendPopupOpen, setIsSendPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);


  // Delete Confirmation Logic
  const handleDeleteConfirmation = (user) => {
    setIsDeleteConfirmationOpen(true);
    setDeleteConfirmationUser(user);
  };

  const handleDeleteConfirmYes = () => {
    updateIsDeleteKey(deleteConfirmationUser);
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmNo = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleVerifyConfirmation = (user) => {
    setIsVerifyConfirmationOpen(true);
    setVerifyConfirmationUser(user);
  };

  const handleVerifyConfirmYes = () => {
    handleVerifySwitchToggle(verifyConfirmationUser);
    setIsVerifyConfirmationOpen(false);
  };

  const handleVerifyConfirmNo = () => {
    setIsVerifyConfirmationOpen(false);
  };

  const handleStatusConfirmation = (user) => {
    setIsStatusConfirmationOpen(true);
    setStatusConfirmationUser(user);
  };

  const handleStatusConfirmYes = () => {
    handleSwitchToggle(statusConfirmationUser);
    setIsStatusConfirmationOpen(false);
  };

  const handleStatusConfirmNo = () => {
    setIsStatusConfirmationOpen(false);
  };

  useEffect(() => {
    // Show toast confirmation when isConfirmationOpen is true
    if (isDeleteConfirmationOpen && deleteConfirmationUser) {
      toast.info(
        <div>
          <div>Are you sure you want to delete this user?</div>
          <Button onClick={handleDeleteConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleDeleteConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false, // Set autoClose to false to keep the toast open until a decision is made.
        }
      );
    } else if (isVerifyConfirmationOpen && verifyConfirmationUser) {
      toast.info(
        <div style={{ width: "800px", }}>
          <div>Are you sure you want to proceed?</div>
          <Button onClick={handleVerifyConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleVerifyConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    } else if (isStatusConfirmationOpen && statusConfirmationUser) {
      toast.info(
        <div style={{ width: "800px" }}>
          <div>Are you sure you want to proceed?</div>
          <Button onClick={handleStatusConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleStatusConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    }
  }, [
    isDeleteConfirmationOpen,
    deleteConfirmationUser,
    isVerifyConfirmationOpen,
    verifyConfirmationUser,
    isStatusConfirmationOpen,
    statusConfirmationUser,
  ]);

  const getUserDataWithSearch = () => {
    // setIsLoading(true);
    axios
      .get(`${BASE_URL}/users`, {
        params: { search: searchQuery },
      })
      .then((res) => {
        // console.log(res?.data?.data)
        setDisplayedUsers(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false); 
      });
  };
// console.log("isLoading",isLoading)
  useEffect(() => {
    getUserDataWithSearch(); // Fetch data with searchQuery
  }, [searchQuery]);

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return displayedUsers?.slice(startIndex, endIndex);
  };
  const [usersData, setUsersData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setUsersData(getCurrentPageData());
  }, [page, displayedUsers]);

  // useEffect(() => {
  //   setDisplayedUsers(users);
  //   // getUserData()
  // }, [users]);
  // console.log("Search",searchQuery)
  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(displayedUsers.length / rowsPerPage) - 1)
    );
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // getUserData();
    getUserDataWithSearch();
  };

  const handleSwitchToggle = (user) => {
    // console.log("user", user);
    const newStatus = !user.active;

    axios
      .put(`${BASE_URL}/updateStatus`, {
        id: user._id,
        active: newStatus,
      })
      .then((res) => {
        console.log("Status updated successfully");
        setDisplayedUsers((prevUserData) =>
          prevUserData.map((u) =>
            u._id === user._id ? { ...u, active: newStatus } : u
          )
        );
        // getUserData();
        getUserDataWithSearch();
      })
      .catch((error) => {
        console.log("Error updating status:", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const updateIsDeleteKey = (user) => {
    const deletedByUser = !user.deletedByUser;
    const id = user?._id;
    axios
      .put(`${BASE_URL}/deleteUser/${id}`, {
        deletedByUser: deletedByUser,
      })
      .then((res) => {
        setDisplayedUsers((prevUserData) =>
          prevUserData.map((u) =>
            u._id === user._id ? { ...u, deletedByUser: deletedByUser } : u
          )
        );
        console.log("deleteRes", res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };

  const handleVerifySwitchToggle = (user) => {
    const isVerified = !user.isVerified;
    // console.log("isVerify",isVerified)
    axios
      .put(`${BASE_URL}/verifyUser`, {
        id: user._id,
        isVerified: isVerified,
      })
      .then((res) => {
        console.log("res", res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };

  // Send Message to Single user logic
  // Function to open the Send popup
  const openSendPopup = (user) => {
    setSelectedUser(user);
    setIsSendPopupOpen(true);
  };

  // Function to close the Send popup
  const closeSendPopup = () => {
    setIsSendPopupOpen(false);
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    setLoading(true);
   if(selectedUser){
    console.log("selectedUser",selectedUser)
    const userId = selectedUser?._id;
    axios
    .post(`${BASE_URL}/send_notification`, {
      id:userId,
      notificationType: "General",
      notificationTitle: notificationTitle,
      notificationBody: notificationBody,
      targetUsers: "singleUser",
      requestValue: false,
    })
    .then((res) => {
      console.log(res);
      setDialogMessage(res?.data?.message);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
        closeSendPopup();
      }, 3000);

      // navigate("/notifications");
      setNotificationTitle("");
      setNotificationBody("");
    })
    .catch((error) => {
      console.log("error", error);
      setDialogMessage(error?.response?.data?.message);
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
    })
    .finally(() => {
      setLoading(false);
    });
   }
  };

  // Edit user logic
  const openEditPopup = (user) => {
    setEditUser(user);
    setIsEditPopupOpen(true);
  };

  const closeEditPopup = () => {
    setEditUser(null);
    setIsEditPopupOpen(false);
  };

  const handleEditUser = (event) => {
    event.preventDefault();
    const id = editUser?._id;
    const fullName = editUser?.fullName;
    const employee_id = editUser?.employee_id;
    const mobile = editUser?.mobile;
     axios
      .put(`${BASE_URL}/addEmployeeId/${id}`, {
        fullName:fullName,
        mobile:mobile,
        employee_id:employee_id
      })
      .then((res) => {
        // console.log("res", res);
        closeEditPopup();
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };
  // console.log("usersData", usersData);

  return (
    <Box>
      <Navbar />
      <Sidenav />
      {isLoading?(<Loader/>):(<Paper className={style.appUsersCard}>
        <Stack direction="row" spacing="47%" className="my-4 mb-2">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "20px" }}
          >
            App Users
          </Typography>
          {isDesktop && (
            <Box>
              <TextField
                style={{ width: "25vw" }}
                size="small"
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                // className={style.searchBoxAppUsers}
              />
            </Box>
          )}
        </Stack>
        <PopupForm open={isPopupOpen} onClose={closePopup} />
        {/* <Divider /> */}
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              // marginTop: "60px",
              textAlign: "center",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" className={style.tableRow}>
                  Full Name
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Email
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Mobile
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Created At
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Status
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Available
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Verify User
                </TableCell>
                <TableCell align="center" className={style.tableRow}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: i % 2 === 1 ? "#a0b5ce" : "transparent",
                    }}
                  >
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.fullName}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.email}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.mobile}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {formatDate(data?.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Switch
                        checked={data?.active}
                        onChange={() => handleStatusConfirmation(data)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.isAvailable?"Yes":"No"}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Switch
                        checked={data?.isVerified}
                        onChange={() => handleVerifyConfirmation(data)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ color: "blue", display: "flex", margin: "auto" }}
                    >
                      <IconButton
                        onClick={() => handleDeleteConfirmation(data)}
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={()=>openEditPopup(data)} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={()=>openSendPopup(data)} color="primary">
                        <SendIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(
            Math.ceil(displayedUsers?.length / rowsPerPage),
            page
          ).map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber - 1)}
              variant={pageNumber - 1 === page ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px" }}
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={
              page === Math.ceil(displayedUsers?.length / rowsPerPage) - 1
            }
          >
            Next
          </Button>
        </Box>
      </Paper>)}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSendPopupOpen} onClose={closeSendPopup}>
        <DialogTitle>Send Message</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
            sx={{
              marginTop: "1.5vw",
              marginBottom: "1.5vw",
              backgroundColor: "rgb(250, 245, 245)",
            }}
          />
          <TextField
            type="text"
            label="Description"
            variant="outlined"
            value={notificationBody}
            onChange={(e) => setNotificationBody(e.target.value)}
            fullWidth
            required
            multiline
            rows={2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSendPopup} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} color="primary">
            {loading ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Send"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit user popup menu */}
      {isEditPopupOpen && editUser && (
        <Dialog open={isEditPopupOpen} onClose={closeEditPopup}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <form onSubmit={handleEditUser}>
              <TextField
                 label={
                  <>
                    Full Name{" "}
                    <span style={{color:"red"}}>*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                value={editUser.fullName}
                onChange={(e) => setEditUser({ ...editUser, fullName: e.target.value })}
                sx={{
                  marginTop: "1.5vw",
                  marginBottom: "1.5vw",
                }}
              />
              <TextField
                 label={
                  <>
                    Email{" "}
                    <span style={{color:"red"}}>*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                value={editUser.email}
                sx={{
                  marginBottom: "1.5vw",
                }}
                disabled
              />
              <TextField
                 label={
                  <>
                    Mobile{" "}
                    <span style={{color:"red"}}>*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                sx={{
                  marginBottom: "1.5vw",
                }}
                value={editUser.mobile}
                onChange={(e) => setEditUser({ ...editUser, mobile: e.target.value })}
              />
              <TextField
                 label={
                  <>
                    Employee ID{" "}
                    <span style={{color:"red"}}>*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                value={editUser.employee_id}
                onChange={(e) => setEditUser({ ...editUser, employee_id: e.target.value })}
              />
              <DialogActions>
                <Button onClick={closeEditPopup} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default DisplayAppUsers;
