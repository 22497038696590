import * as React from "react";
import { styled, alpha, makeStyles } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppStore } from "../../appStore";
import { useNavigate, Link } from "react-router-dom";
import style from "./Navbar.module.css";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  ListItemButton,
  TextField,
} from "@mui/material";
import axios from "axios";
// import BASE_URL from "../../Config";
import { useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log("Base_url",BASE_URL)
const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function Navbar() {
  const [user, setUser] = React.useState("");
  const [resetDialogOpen, setResetDialogOpen] = React.useState(false);
  // const [oldPassword, setOldPassword] = React.useState("");
  // const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePopoverAnchorEl, setProfilePopoverAnchorEl] =
    React.useState(null);
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const email = localStorage.getItem("email");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [newPasswordData, setNewPasswordData] = useState({
    newPassword: "",
  });
  const [oldPasswordData, setOldPasswordData] = useState({
    oldPassword: "",
  });
  const { oldPassword } = oldPasswordData;
  const { newPassword } = newPasswordData;
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [loadingReset,setLoadingReset] = useState(false);
const [loadingUpdateProfile,setLoadingUpdateProfile] = useState(false);
const isDesktop = useMediaQuery("(min-width: 960px)");
  // Edit Profile Logic
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [editedUser, setEditedUser] = useState({
    fullName: user?.fullName || "",
    mobile: user?.mobile || "",
  });

  const handleOpenProfileDialog = () => {
    setEditedUser({
      fullName: user?.fullName || "",
      email: email,
      mobile: user?.mobile || "",
    });
    setProfileDialogOpen(true);
  };

  const handleCloseProfileDialog = () => {
    setProfileDialogOpen(false);
  };

  const handleProfileChange = (event) => {
    const { name, value } = event.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  let id = localStorage.getItem("id");
  const handleProfileSubmit = () => {
    // console.log("id", id);
    setLoadingUpdateProfile(true); 
    axios
      .put(`${BASE_URL}/updateProfileAdmin/` + id, editedUser)
      .then((res) => {
        setUser(res.data);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
          handleCloseProfileDialog();
          getProfileDetails();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
          handleCloseProfileDialog();
        }, 3000);
      })
      .finally(() => {
        setLoadingUpdateProfile(false); 
      });
  };

  // Fetching User Details
  const getProfileDetails = () => {
    axios
      .get(`${BASE_URL}/getProfileAdmin/` + id)
      .then((res) => {
        // console.log("res", res);
        setUser(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  React.useEffect(() => {
    getProfileDetails();
  }, []);
  const handleResetDialogOpen = () => {
    setOldPasswordData({ oldPassword: " " });
    setResetDialogOpen(true);
  //  setOldPasswordData({ oldPassword: "" });
    handleProfilePopoverClose();
  };

  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
    setOldPasswordData({ oldPassword: "" });
    setNewPasswordData({ newPassword: "" });
    setConfirmPassword("")
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    navigate("/signin");
    handleMenuClose();
  };

  const handleProfilePopoverOpen = (event) => {
    setProfilePopoverAnchorEl(event.currentTarget);
    handleMenuClose();
  };
  const handleProfilePopoverClose = () => {
    setProfilePopoverAnchorEl(null);
  };

  // Reset Password Logic Here
  const handleOldPasswordChange = (e) => {
    const { name, value } = e.target;
    setOldPasswordData({ ...oldPasswordData, [name]: value });
    if (name === "oldPassword") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setOldPasswordData((prevData) => ({ ...prevData, isValidPassword }));
    }
  };
  const handleNewPasswordChange = (e) => {
    const { name, value } = e.target;
    setNewPasswordData({ ...newPasswordData, [name]: value });
    if (name === "newPassword") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setNewPasswordData((prevData) => ({ ...prevData, isValidPassword }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(newPassword === confirmPasswordValue);
  };

  const handleResetPassword = () => {
    if (oldPasswordData.oldPassword === "") {
      setOldPasswordError(true);
      return;
    }
    if (newPasswordData.newPassword === "") {
      setNewPasswordError(true);
      return;
    }
    if (confirmPassword === "") {
      setConfirmPasswordError(true);
      return;
    }
     if (!newPasswordData.isValidPassword) {
        setDialogMessage(
          "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, at least one numeric digit (0-9), and at least one special character."
        );
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        return;
      }
      if (!passwordsMatch || newPassword !== confirmPassword) {
        setDialogMessage("Passwords Don't Match");
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        return;
      }
    setLoadingReset(true)
    axios
      .patch(`${BASE_URL}/resetPassword`, {
        email: email,
        newPassword: newPassword,
        oldPassword: oldPassword,
      })
      .then((res) => {
        console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        setOldPasswordData({ oldPassword: "" });
        setNewPasswordData({ newPassword: "" });
        setConfirmPassword("");
        handleResetDialogClose();
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setDialogMessage(error.response?.data?.message);
        setOldPasswordData({ oldPassword: "" });
        setNewPasswordData({ newPassword: "" });
        setConfirmPassword("");
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingReset(false); 
      });
  };

  const ResetPasswordDialogId = "reset-password-popover";
  const renderResetPasswordDialog = (
    <Dialog
      id={ResetPasswordDialogId}
      open={resetDialogOpen}
      onClose={handleResetDialogClose}
      aria-labelledby="reset-dialog-title"
    >
      <DialogTitle className={style.resetTitle} id="reset-dialog-title">Reset Password</DialogTitle>
      <Divider />
      <DialogContent className={style.resetDialogBox} style={{ width: "450px" }}>
        {/* <DialogContentText>Old Password</DialogContentText> */}
        <TextField
        className={style.resetOldPasswordInput}
          autoFocus
          autocomplete="off"
          margin="dense"
          id="oldPassword"
          label="Old Password"
          type={showOldPassword ? "text" : "password"}
          fullWidth
          value={oldPassword}
          name="oldPassword"
          onChange={handleOldPasswordChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleOldPasswordVisibility}>
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
          error={
            oldPasswordError }
        />
        {/* <DialogContentText>New Password</DialogContentText> */}
        <TextField
         className={style.resetNewPasswordInput}
          // autoFocus
          // style={{marginTop:"20px",marginBottom:"20px"}}
          margin="dense"
          id="newPassword"
          label="New Password"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          value={newPassword}
          name="newPassword"
          onChange={handleNewPasswordChange}
          required
          error={
            newPasswordError ||
            (newPassword &&
              newPassword.length > 0 &&
              !newPasswordData.isValidPassword)
          }
          helperText={
            newPassword &&
            newPassword.length > 0 &&
            !newPasswordData.isValidPassword
              ? "Password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, at least one numeric digit (0-9), and at least one special character."
              : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleNewPasswordVisibility}>
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        {/* <DialogContentText>Confirm New Password</DialogContentText> */}
        <TextField
         className={style.resetConfirmPasswordInput}
          // autoFocus
          margin="dense"
          id="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          error={confirmPasswordError || !passwordsMatch}
          helperText={!passwordsMatch && "Passwords don't match"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          className={style.resetPasswordCancelBtn}
          onClick={handleResetDialogClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className={style.resetPasswordContinueBtn}
          onClick={handleResetPassword}
          color="primary"
          disabled={loadingReset}
        >
           {loadingReset ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Reset"
            )}
        </Button>
      </DialogActions>
    </Dialog>
  );
  const profilePopoverId = "profile-popover";
  const renderProfilePopover = (
    <Popover
      id={profilePopoverId}
      open={Boolean(profilePopoverAnchorEl)}
      anchorEl={profilePopoverAnchorEl}
      onClose={handleProfilePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 300,
          marginTop: -70,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <CloseIcon
          onClick={handleProfilePopoverClose}
          style={{ cursor: "pointer" }}
        />
      </div>
      <List>
        <ListItem>
          {/* <Avatar src={avatar} alt="Profile" sx={{ width: 100, height: 100 }} /> */}
          <ListItem>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={handleOpenProfileDialog}
            >
              <EditIcon />
            </IconButton>
          </ListItem>
        </ListItem>
        <ListItem>
          <ListItemText primary={`Name: ${user?.fullName}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Email: ${user?.email}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Mobile: ${user?.mobile}`} />
        </ListItem>

        <Divider />
        <ListItemButton
          onClick={handleResetDialogOpen}
          style={{
            backgroundColor: "rgb(0, 100, 255)",
            color: "#ffffff",
            width: "60%",
            margin: "0 auto",
            display: "block",
            textAlign: "center",
            height: "45px",
            borderRadius: "5px",
            marginTop: "4px",
          }}
        >
          <ListItemText primary="Reset Password" />
        </ListItemButton>
      </List>
    </Popover>
  );

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          width: 150,
          marginTop: 45,
        },
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleProfilePopoverOpen}>
        <p>Profile</p>
      </MenuItem>

      <MenuItem onClick={handleLogout}>
        <Link style={{ textDecoration: "none", color: "grey" }} to="/signin">
          Logout
        </Link>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ width: 240, marginTop: 16 }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#ffffff",
          color: "#2f2f2f",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px 0px",
        }}
        elevation={0}
      >
        <Toolbar>
       
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => {
                updateDopen(!dopen);
              }}
            >
              <MenuIcon />
            </IconButton>
       
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: "block" } }}
          >
            <p>Capital Job Link</p>
            {/* <img
              src={appLogo}
              alt="Capital Job Link Logo"
              style={{ width:"120px", height: "60px", marginTop: "10px" }}
            /> */}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: {  md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderProfilePopover}
      {renderResetPasswordDialog}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height:"auto", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Profile Dialog */}
      <Dialog
        open={profileDialogOpen}
        onClose={handleCloseProfileDialog}
        // style={{ marginLeft: "650px" }}
      >
        <DialogTitle className={style.profileDialogTitle}>Update Profile</DialogTitle>
        <Divider/>
        <DialogContent
          className={style.profileCard}
        >
          {/* <ListItem>
            <Avatar
              alt="Profile"
              src={editedUser.avatar || avatar} // Use the edited avatar or default avatar
              sx={{
                width: 60,
                height: 60,
                marginLeft: "180px",
                marginBottom: "30px",
              }}
            />
          </ListItem> */}
          {/* <input
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
            style={{ marginBottom: "10px" }}
          /> */}

          <TextField
            className={style.nameInputBox}
            label="Full Name"
            onChange={handleProfileChange}
            variant="outlined"
            color="secondary"
            type="text"
            fullWidth
            value={editedUser.fullName}
            name="fullName"
            // sx={{ display: { md: {fontSize:"15vw"},xl:{fontSize:"15vw"} } }}
          />

          <TextField
            className={style.emailInputBox}
            label="Email"
            variant="outlined"
            color="secondary"
            type="text"
            fullWidth
            value={editedUser.email}
            disabled // Disable email field as it should not be editable
          />

          <TextField
             className={style.mobileInputBox}
            label="Mobile"
            onChange={handleProfileChange}
            variant="outlined"
            color="secondary"
            type="text"
            fullWidth
            name="mobile"
            value={editedUser.mobile}
          />
        </DialogContent>
        <DialogActions>
          <Button className={style.profileCancelBtn} onClick={handleCloseProfileDialog} >
            Cancel
          </Button>
          <Button className={style.profileContinueBtn} onClick={handleProfileSubmit}  disabled={loadingUpdateProfile}>
          {loadingUpdateProfile ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Submit"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
