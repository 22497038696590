import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import NotificationsForm from "./NotificationForm";
import style from "./notificationForm.module.css";

const NotificationForm = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} width="100%" sx={{height:"auto",position:"absolute",top:"-10vh"}}>
      <DialogTitle className={style.notificationTitle}>Add New Notification</DialogTitle>
      <DialogContent>
        <NotificationsForm onClose={onClose} />
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default NotificationForm;
