import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState, useEffect } from "react";
import PopupForm from "./PopupForm";
import { generatePageNumbers } from "../../Components/Pagination/Pagination";
import axios from "axios";
import Navbar from "../../Components/Navbar/Navbar";
import Sidenav from "../../Components/Navbar/SideNav";
import style from "./adminUsers.module.css";
import Loader from "../../Components/LoaderComponent";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log("UsersBaseUrl",BASE_URL)

const DisplayUsers = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [page, setPage] = useState(0);
  // const rowsPerPage = Math.ceil(displayedUsers.length / 10);
  const rowsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const isDesktop = useMediaQuery("(min-width: 667px)");

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [deleteConfirmationUser, setDeleteConfirmationUser] = useState(null);
  const [isStatusConfirmationOpen, setIsStatusConfirmationOpen] =
    useState(false);
  const [statusConfirmationUser, setStatusConfirmationUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Delete Confirmation Logic
  const handleDeleteConfirmation = (user) => {
    setIsDeleteConfirmationOpen(true);
    setDeleteConfirmationUser(user);
  };

  const handleDeleteConfirmYes = () => {
    updateIsDeleteKey(deleteConfirmationUser);
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmNo = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleStatusConfirmation = (user) => {
    setIsStatusConfirmationOpen(true);
    setStatusConfirmationUser(user);
  };

  const handleStatusConfirmYes = () => {
    handleSwitchToggle(statusConfirmationUser);
    setIsStatusConfirmationOpen(false);
  };

  const handleStatusConfirmNo = () => {
    setIsStatusConfirmationOpen(false);
  };

  useEffect(() => {
    // Show toast confirmation when isConfirmationOpen is true
    if (isDeleteConfirmationOpen && deleteConfirmationUser) {
      toast.info(
        <div>
          <div>Are you sure you want to delete this user?</div>
          <Button onClick={handleDeleteConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleDeleteConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false, // Set autoClose to false to keep the toast open until a decision is made.
        }
      );
    } else if (isStatusConfirmationOpen && statusConfirmationUser) {
      toast.info(
        <div style={{ width: "800px" }}>
          <div>Are you sure you want to proceed?</div>
          <Button onClick={handleStatusConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleStatusConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    }
  }, [
    isDeleteConfirmationOpen,
    deleteConfirmationUser,
    isStatusConfirmationOpen,
    statusConfirmationUser,
  ]);

  const getUserDataWithSearch = () => {
    // setIsLoading(true)
    axios
      .get(`${BASE_URL}/usersAdmin`, {
        params: { search: searchQuery },
      })
      .then((res) => {
        setDisplayedUsers(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      }).finally(() => {
        setIsLoading(false); 
      });
  };

  useEffect(() => {
    getUserDataWithSearch(); // Fetch data with searchQuery
  }, [searchQuery]);

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return displayedUsers?.slice(startIndex, endIndex);
  };
  const [usersData, setUsersData] = useState(getCurrentPageData());

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setUsersData(getCurrentPageData());
  }, [page, displayedUsers]);

  // useEffect(() => {
  //   setDisplayedUsers(users);
  //   // getUserData()
  // }, [users]);
  // console.log("Search",searchQuery)
  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(displayedUsers.length / rowsPerPage) - 1)
    );
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // getUserData();
    getUserDataWithSearch();
  };

  const handleSwitchToggle = (user) => {
    // console.log("user", user);
    const newStatus = !user.active;

    axios
      .put(`${BASE_URL}/updateStatusAdmin`, {
        id: user._id,
        active: newStatus,
      })
      .then((res) => {
        console.log("Status updated successfully");
        setDisplayedUsers((prevUserData) =>
          prevUserData.map((u) =>
            u._id === user._id ? { ...u, active: newStatus } : u
          )
        );
        // getUserData();
        getUserDataWithSearch();
      })
      .catch((error) => {
        console.log("Error updating status:", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const updateIsDeleteKey = (user) => {
    const isDeleted = !user.isDeleted;
    const response = axios
      .put(`${BASE_URL}/deleteUserAdmin`, {
        id: user._id,
        isDeleted: isDeleted,
      })
      .then((res) => {
        setDisplayedUsers((prevUserData) =>
          prevUserData.map((u) =>
            u._id === user._id ? { ...u, isDeleted: isDeleted } : u
          )
        );
        console.log(response);
        setDialogMessage("User Deleted Successfully");
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };
  // console.log("usersData", usersData);

  return (
    <Box>
      <Navbar />
      <Sidenav />
      {isLoading?(<Loader/>):(<Paper className={style.displayUsersCard}>
        {/* <Stack direction="row" spacing={22} className="my-4 mb-2"> */}
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          Users List
        </Typography>
        <Button
          className={style.addUserButton}
          variant="outlined"
          color="primary"
          onClick={openPopup}
        >
          ADD User
        </Button>
        {isDesktop && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              marginTop: "-55px",
            }}
          >
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              // className={style.searchBoxUsers}
              style={{ width: "25vw", marginRight: "30px" }}
            />
          </Box>
        )}

        {/* </Stack> */}
        <PopupForm open={isPopupOpen} onClose={closePopup} />
        {/* <Divider /> */}
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              margin: "auto",
              marginTop: "80px",
              textAlign: "center",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" className={style.tableRow}>
                  Name
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Email
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Mobile
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Created At
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Status
                </TableCell>
                <TableCell align="left" className={style.tableRow}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: i % 2 === 1 ? "#a0b5ce" : "transparent",
                    }}
                  >
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.fullName}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.email}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {data?.mobile}
                    </TableCell>
                    <TableCell align="left" className={style.tableColumn}>
                      {formatDate(data?.createdAt)}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Switch
                        checked={data?.active}
                        onChange={() => handleStatusConfirmation(data)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => handleDeleteConfirmation(data)} // Replace handleDelete with your actual delete function
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            onClick={handlePreviousPage}
            variant="outlined"
            color="primary"
            disabled={page === 0}
          >
            Previous
          </Button>
          {generatePageNumbers(
            Math.ceil(displayedUsers?.length / rowsPerPage),
            page
          ).map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber - 1)}
              variant={pageNumber - 1 === page ? "contained" : "outlined"}
              color="primary"
              sx={{ margin: "0 2px" }}
            >
              {pageNumber}
            </Button>
          ))}
          <Button
            onClick={handleNextPage}
            variant="outlined"
            color="primary"
            disabled={
              page === Math.ceil(displayedUsers?.length / rowsPerPage) - 1
            }
          >
            Next
          </Button>
        </Box>
      </Paper>)}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisplayUsers;
