// Chat Folder Name in firebase
// Production
export const chatFolderName = "chats"

// Dev
// export const chatFolderName = "dev_chats"

// Production
export const fileStorageFolder = "Production"

// Dev
// export const fileStorageFolder = "Dev"

// export const Site_Key = "6Ld6ntcnAAAAAJLkImmzRbjtITqueEJ_SKHlbUCP"
// export const Secret_Key = "6Ld6ntcnAAAAANqEI26Z6iidaEfc-vZQSBmoAcIr"

