import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useEffect } from "react";
import PopupForm from "./NotificationPopupForm";
import { generatePageNumbers } from "../../Components/Pagination/Pagination";
import axios from "axios";
import Navbar from "../../Components/Navbar/Navbar";
import Sidenav from "../../Components/Navbar/SideNav";
import style from "./displayNotifications.module.css";
import Loader from "../../Components/LoaderComponent";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const DisplayUsers = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [userData, setUserData] = useState({});
  const [isTargetUsersMenuOpen, setIsTargetUsersMenuOpen] = useState(false);
  const [isUsersMenuOpen, setIsUsersMenuOpen] = useState(false);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
  useState(false);
const [isDeleteConfirmationOpenNotifications, setIsDeleteConfirmationOpenNotifications] =
useState(false);
const [deleteConfirmationNotification, setDeleteConfirmationNotification] = useState(null);
const [isArchiveConfirmationOpen, setIsArchiveConfirmationOpen] =
useState(false);
const [archiveConfirmationNotification, setArchiveConfirmationNotification] = useState(null);
const [isArchiveConfirmationOpenNotifications, setIsArchiveConfirmationOpenNotifications] =
useState(false);
const [isArchiveChecked,setIsArchiveChecked] = useState(false);

  const getUserDataWithSearch = () => {
    setIsLoading(true);
    axios
      .get(
        `${BASE_URL}/getAllNotifications`
        // {
        //   params: { search: searchQuery },
        // }
      )
      .then((res) => {
        setDisplayedNotifications(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDataWithSearch(); // Fetch data with searchQuery
  }, [searchQuery]);

  const getCurrentPageData = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return displayedNotifications?.slice(startIndex, endIndex);
  };
  const [notificationsData, setNotificationsData] = useState(
    getCurrentPageData()
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setNotificationsData(getCurrentPageData());
  }, [page, displayedNotifications]);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) =>
      Math.min(
        prevPage + 1,
        Math.ceil(displayedNotifications?.length / rowsPerPage) - 1
      )
    );
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // getUserData();
    getUserDataWithSearch();
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(timestamp).toLocaleString(undefined, options);
  };
  // console.log("isArchiveCheckedCheckbox",isArchiveChecked)
  const updateIsDeleteKey = (notification) => {
    const isArchiveChecked = localStorage.getItem("isArchiveChecked")
    const isDeleted = !notification.isDeleted;
    const isArchived = true;
    axios
      .put(`${BASE_URL}/deleteNotification`, {
        id: notification._id,
        isDeleted: isDeleted,
        isArchived:isArchived,
        isArchiveChecked:isArchiveChecked,
      })
      .then((res) => {
        setDisplayedNotifications((prevUserData) =>
          prevUserData.map((u) =>
            u._id === notification._id ? { ...u, isDeleted: isDeleted } : u
          )
        );
        localStorage.removeItem("isArchiveChecked")
        setIsArchiveChecked(false)
        //   console.log(res)
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };
  const handleArchive = (notification) => {
    const isArchived = !notification.isArchived;
    axios
      .put(`${BASE_URL}/archiveNotification`, {
        id: notification._id,
        isArchived: isArchived,
      })
      .then((res) => {
        console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };
  // console.log("Data", notificationsData);
  const handleNotificationUsers = (notificationId) => {
    const id = notificationId?._id;
    setNotificationUsers([]);
    axios
      .get(`${BASE_URL}/allNotificationUsers/${id}`)
      .then((res) => {
        // console.log("res", res);
        setNotificationUsers(res?.data?.data);
      })
      .catch((error) => {
        console.log("Error updating status:", error);
      });
  };
  const openTargetUsersMenu = (data) => {
    handleNotificationUsers(data);
    setTimeout(() => {
      setIsTargetUsersMenuOpen(true);
    }, 400);
  };

  const openUsersMenu = (data) => {
    setUserData(data);
    setIsUsersMenuOpen(true);
  };

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (notification) => {
    const updatedSelection = [...selectedNotifications];
    const index = updatedSelection.findIndex((selected) => selected === notification._id);

    if (index === -1) {
      updatedSelection.push(notification._id);
    } else {
      updatedSelection.splice(index, 1);
    }

    setSelectedNotifications(updatedSelection);
  };

  // Function to check if a notification is selected
  const isNotificationSelected = (notification) => {
    return selectedNotifications.includes(notification._id);
  };
  
   const handleIsDeleteKey = () => {
    const isArchiveChecked = localStorage.getItem("isArchiveChecked")
    const isDeleted = true;
      const isArchived = true;
    axios
      .put(`${BASE_URL}/deleteNotifications`, {
        ids: selectedNotifications,
        isDeleted: isDeleted,
        isArchived: isArchived,
        isArchiveChecked:isArchiveChecked,
      })
      .then((res) => {
        localStorage.removeItem("isArchiveChecked")
        setIsArchiveChecked(false)
          console.log(res)
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
        setSelectedNotifications([])
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };
  const handleIsArchiveKey = () => {
    const isArchived = true;
    axios
      .put(`${BASE_URL}/archiveNotifications`, {
        ids: selectedNotifications,
        isArchived: isArchived,
      })
      .then((res) => {
        console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
        getUserDataWithSearch();
        setSelectedNotifications([])
      })
      .catch((error) => {
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      });
  };

  // Delete Confirmation Logic
  const handleDeleteConfirmation = (user) => {
    localStorage.removeItem("isArchiveChecked")
    setIsDeleteConfirmationOpen(true);
    setDeleteConfirmationNotification(user);
  };
  const handleDeleteConfirmationNotifications = () => {
    localStorage.removeItem("isArchiveChecked")
    setIsDeleteConfirmationOpenNotifications(true);
  };

  const handleDeleteConfirmYes = () => {
    updateIsDeleteKey(deleteConfirmationNotification);
    setIsDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmYesNotifications = () => {
    handleIsDeleteKey();
    setIsDeleteConfirmationOpenNotifications(false);
  };
  const handleDeleteConfirmNo = () => {
    setIsDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmNoNotifications = () => {
    setIsDeleteConfirmationOpenNotifications(false);
  };

  // Archive Confirmation Logic
  const handleArchiveConfirmation = (user) => {
    setIsArchiveConfirmationOpen(true);
    setArchiveConfirmationNotification(user);
  };
  const handleArchiveConfirmationNotifications = () => {
    setIsArchiveConfirmationOpenNotifications(true);
  };

  const handleArchiveConfirmYes = () => {
    handleArchive(archiveConfirmationNotification);
    setIsArchiveConfirmationOpen(false);
  };
  const handleArchiveConfirmYesNotifications = () => {
    handleIsArchiveKey();
    setIsArchiveConfirmationOpenNotifications(false);
  };

  const handleArchiveConfirmNo = () => {
    setIsArchiveConfirmationOpen(false);
  };
  const handleArchiveConfirmNoNotifications = () => {
    setIsArchiveConfirmationOpenNotifications(false);
  };

  const handleArchiveCheckbox = (e) => {
    localStorage.removeItem("isArchiveChecked")
    e.stopPropagation();
    setIsArchiveChecked((prevIsArchiveChecked) => !prevIsArchiveChecked);
    };
  localStorage.setItem("isArchiveChecked",isArchiveChecked)
  useEffect(() => {
    // Show toast confirmation when isConfirmationOpen is true
    if (isDeleteConfirmationOpen && deleteConfirmationNotification) {
      toast.info(
        <div>
        <div>Are you sure you want to delete?</div>
        <div style={{marginTop:"10px"}}>
          <label>
            <input
              type="checkbox"
              onClick={(e) => handleArchiveCheckbox(e)}
              // checked={isArchiveChecked}
            />
            Do you want to archive?
          </label>
        </div>
        <Button onClick={handleDeleteConfirmYes} color="primary">
          Yes
        </Button>
        <Button onClick={handleDeleteConfirmNo} color="secondary">
          No
        </Button>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Set autoClose to false to keep the toast open until a decision is made.
      }
      
      );
    } else if (isArchiveConfirmationOpen && archiveConfirmationNotification) {
      toast.info(
        <div style={{ width: "1200px", }}>
          <div>Are you sure you want to archive?</div>
          <Button onClick={handleArchiveConfirmYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleArchiveConfirmNo} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
     }else if (isDeleteConfirmationOpenNotifications && selectedNotifications) {
      toast.info(
        <div style={{ width: "1200px", }}>
          <div>Are you sure you want to delete?</div>
          <div style={{marginTop:"10px"}}>
          <label>
            <input
              type="checkbox"
              onClick={(e) => handleArchiveCheckbox(e)}
              // checked={isArchiveChecked}
            />
            Do you want to archive?
          </label>
        </div>
          <Button onClick={handleDeleteConfirmYesNotifications} color="primary">
            Yes
          </Button>
          <Button onClick={handleDeleteConfirmNoNotifications} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    }else if (isArchiveConfirmationOpenNotifications && selectedNotifications) {
      toast.info(
        <div style={{ width: "1200px", }}>
          <div>Are you sure you want to archive?</div>
          <Button onClick={handleArchiveConfirmYesNotifications} color="primary">
            Yes
          </Button>
          <Button onClick={handleArchiveConfirmNoNotifications} color="secondary">
            No
          </Button>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
     }
  }, [
    isDeleteConfirmationOpen,
    isArchiveConfirmationOpen,
    isDeleteConfirmationOpenNotifications,
    isArchiveConfirmationOpenNotifications,
  ]);
// console.log(isArchiveChecked)
  return (
    <Box>
      <Navbar />
      <Sidenav />
      {isLoading ? (
        <Loader />
      ) : (
        <Paper className={style.displayNotificationsCard}>
          {/* <Stack direction="row" spacing={22} className="my-4 mb-2"> */}
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ padding: "20px" }}
          >
            Notifications
          </Typography>
          <Button
            className={style.addNewButton}
            variant="outlined"
            color="primary"
            onClick={openPopup}
          >
            Send New Notification
          </Button>
          {selectedNotifications.length > 0 && (
          <div className={style.archivedDeleted}>
            {/* Delete Icon */}
            <IconButton onClick={() => handleDeleteConfirmationNotifications()} color="primary">
              <DeleteIcon />
            </IconButton>
            {/* Archive Icon */}
            <IconButton onClick={() =>handleArchiveConfirmationNotifications()} color="primary">
              <ArchiveIcon />
            </IconButton>
          </div>
        )}
          <PopupForm open={isPopupOpen} onClose={closePopup} />
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                fontFamily: "Arial, Helvetica, sans-serif",
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                marginTop: "60px",
                textAlign: "center",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={style.tableRow}>
                    Title
                  </TableCell>
                  <TableCell align="left" className={style.tableRow}>
                    Description
                  </TableCell>
                  <TableCell align="left" className={style.tableRow}>
                    Type
                  </TableCell>
                  <TableCell align="left" className={style.tableRow}>
                    Users
                  </TableCell>
                  <TableCell align="start" className={style.tableRow}>
                    Request Response
                  </TableCell>
                  <TableCell align="center" className={style.tableRow}>
                    Date
                  </TableCell>
                  <TableCell align="center" className={style.tableRow}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationsData?.map((data, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        backgroundColor:
                          i % 2 === 1 ? "#a0b5ce" : "transparent",
                      }}
                    >
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.notificationTitle}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.notificationBody}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.notificationType}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={style.tableColumn}
                        onClick={() => openTargetUsersMenu(data)}
                        style={{ cursor: "pointer", color: "rgb(0, 100, 255)" }}
                      >
                        {data?.targetUsers === "allUsers" && "All Users"}
                        {data?.targetUsers === "verifiedUsers" &&
                          "Verified Users"}
                        {data?.targetUsers === "specificUser" &&
                          "Specific Users"}
                        {data?.targetUsers === "availableUsers" &&
                          "Available Users"}
                        {data?.targetUsers === "singleUser" && "Single User"}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.requestValue === true ? "Yes" : "No"}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {formatDate(data?.createdAt)}
                      </TableCell>
                      <TableCell align="left">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            onClick={() => handleDeleteConfirmation(data)}
                            color="primary"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleArchiveConfirmation(data)}
                            color="primary"
                            disabled={data?.isArchived}
                          >
                            <ArchiveIcon />
                          </IconButton>
                          <Checkbox
                            checked={isNotificationSelected(data)}
                            onChange={() => handleCheckboxToggle(data)}
                            color="primary"
                            inputProps={{ "aria-label": "Archive checkbox" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Button
              onClick={handlePreviousPage}
              variant="outlined"
              color="primary"
              disabled={page === 0}
            >
              Previous
            </Button>
            {generatePageNumbers(
              Math.ceil(displayedNotifications?.length / rowsPerPage),
              page
            ).map((pageNumber) => (
              <Button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber - 1)}
                variant={pageNumber - 1 === page ? "contained" : "outlined"}
                color="primary"
                sx={{ margin: "0 2px" }}
              >
                {pageNumber}
              </Button>
            ))}
            <Button
              onClick={handleNextPage}
              variant="outlined"
              color="primary"
              disabled={
                page ===
                Math.ceil(displayedNotifications?.length / rowsPerPage) - 1
              }
            >
              Next
            </Button>
          </Box>
        </Paper>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto",
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/* Target Users */}
      <Dialog
        open={isTargetUsersMenuOpen}
        onClose={() => setIsTargetUsersMenuOpen(false)}
        aria-labelledby="target-users-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Target Users Details
          </Typography>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                fontFamily: "Arial, Helvetica, sans-serif",
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                marginTop: "40px",
                textAlign: "center",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={style.tableRow1}>
                    Name
                  </TableCell>
                  <TableCell align="left" className={style.tableRow1}>
                    Email
                  </TableCell>
                  <TableCell align="left" className={style.tableRow1}>
                    Response Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationUsers?.map((data, i) => {
                  return (
                    <TableRow
                      key={i}
                      sx={{
                        backgroundColor:
                          i % 2 === 1 ? "#a0b5ce" : "transparent",
                      }}
                    >
                      <TableCell
                        align="left"
                        className={style.tableColumn}
                        onClick={() => openUsersMenu(data)}
                        style={{ cursor: "pointer", color: "rgb(0, 100, 255)" }}
                      >
                        {data?.fullName}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.email}
                      </TableCell>
                      <TableCell align="left" className={style.tableColumn}>
                        {data?.role}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsTargetUsersMenuOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* User Data Dialog */}
      <Dialog
        open={isUsersMenuOpen}
        onClose={() => setIsUsersMenuOpen(false)}
        aria-labelledby="target-users-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Profile Details
          </Typography>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                fontFamily: "Arial, Helvetica, sans-serif",
                borderCollapse: "collapse",
                width: "100%",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={style.tableRow}>
                    Name
                  </TableCell>
                  <TableCell align="left" className={style.tableRow}>
                    Email
                  </TableCell>
                  <TableCell align="left" className={style.tableRow}>
                    Mobile
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableCell align="left" className={style.tableColumn}>
                  {userData?.fullName}
                </TableCell>
                <TableCell align="left" className={style.tableColumn}>
                  {userData?.email}
                </TableCell>
                <TableCell align="left" className={style.tableColumn}>
                  {userData?.mobile}
                </TableCell>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUsersMenuOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DisplayUsers;
