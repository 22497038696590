import React, {useState } from "react";
import PeopleIcon from "@mui/icons-material/People";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid"
import {
  TextField,
  Button,
  Box,
  Stack,
  InputAdornment,
  IconButton,
  DialogContentText,
  DialogActions,
  DialogContent,
  Dialog,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import style from "./usersForm.module.css";



const BASE_URL = process.env.REACT_APP_BASE_URL;
const initData = {
  fullName: "",
  mobile: "",
  email: "",
  employee_id:"",
  password: "",
  confirmPassword: "",
};

const SignupPage = ({ onClose }) => {
  const [userData, setUserData] = useState({
    initData,
    isValidPassword: false,
  });
  const { fullName, mobile, email,employee_id, password } = userData;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [signupEmailError, setSignupEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [mobileError, setMobileError] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    if (name === "password") {
      const isValidPassword =
        value.length >= 8 &&
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          value
        );

      // Update the userData object with the password validation result
      setUserData((prevData) => ({ ...prevData, isValidPassword }));
    }
    if (name === "email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i;
      const inputValue = e.target.value;

      if (!emailRegex.test(inputValue)) {
        setSignupEmailError(true);
      } else {
        setSignupEmailError(false);
      }

      setUserData((prevData) => ({ ...prevData, inputValue }));
    }
    if (name === "mobile") {
      const phoneRegex = /^\d{10}$/; // Example: 10-digit phone number
      const isValidMobile = phoneRegex.test(value);
      setMobileError(!isValidMobile);
      setUserData((prevData) => ({ ...prevData, mobile: value }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordsMatch(password === confirmPasswordValue);
  };

   function handleSubmit(event) {
    event.preventDefault();
    if (signupEmailError) {
      setDialogMessage("Please Enter Valid Email");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    if (mobileError) {
      setDialogMessage("Please Enter Valid Mobile Number");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    if (!passwordsMatch || password !== confirmPassword) {
      setDialogMessage("Passwords Don't Match");
      setDialogOpen(true);
      setTimeout(() => {
        setDialogOpen(false);
      }, 3000);
      return;
    }
    setLoading(true);
    axios
      .post(`${BASE_URL}/admin_signup`, {
        fullName: fullName,
        countryCode: countryCode,
        mobile: mobile,
        email: email,
        employee_id:employee_id,
        password: password,
      })
      .then((res) => {
        // console.log(res);
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
          onClose();
        }, 3000);
        
        navigate("/users");
        setUserData(initData);
        setConfirmPassword("");
      })
      .catch((error) => {
        console.log("error", error);
        setDialogMessage(error?.response?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 3000);
        setUserData(initData);
        setConfirmPassword("");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <React.Fragment>
      <Box style={{ width: "100%", display: "flex" }}>
        <form
          className={style.Signup}
          onSubmit={handleSubmit}
          // action={<Link to="/login" />}
          action={<Link to="/signin" />}
        >
          <TextField
          autoFocus
            type="text"
            variant="outlined"
            // color="secondary"
            label="Full Name"
            onChange={handleChange}
            name="fullName"
            value={fullName}
            fullWidth
            required
            sx={{marginTop:"15px", marginBottom: "30px" }}
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PeopleIcon />
                </InputAdornment>
              ),
              
            }}
          />
          <TextField
          // autoFocus
            type="email"
            variant="outlined"
            // color="secondary"
            label="Email"
            required
            onChange={handleChange}
            value={email}
            name="email"
            fullWidth
            error={signupEmailError}
            helperText={
              signupEmailError ? "Please enter a valid email address" : ""
            }
            inputProps={{ autoComplete: "new-email" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
              
            }}
          />
          <Stack
            style={{ marginBottom: "30px", marginTop: "30px" }}
            direction="row"
            spacing={2}
          >
            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              name="countryCode"
              required
            >
              <MenuItem value="+91">+91 (India)</MenuItem>
              <MenuItem value="+1">+1 (United States)</MenuItem>
              <MenuItem value="+44">+44 (United Kingdom)</MenuItem>
              <MenuItem value="+33">+33 (France)</MenuItem>
              <MenuItem value="+49">+49 (Germany)</MenuItem>
              <MenuItem value="+1">+1 (Canada)</MenuItem>
              <MenuItem value="+61">+61 (Australia)</MenuItem>
              <MenuItem value="+39">+39 (Italy)</MenuItem>
              <MenuItem value="+34">+34 (Spain)</MenuItem>
              <MenuItem value="+86">+86 (China)</MenuItem>
              <MenuItem value="+81">+81 (Japan)</MenuItem>
              <MenuItem value="+7">+7 (Russia)</MenuItem>
            </Select>
            <TextField
            // autoFocus
              type="text"
              variant="outlined"
              // color="secondary"
              label="Mobile"
              required
              onChange={handleChange}
              value={mobile}
              name="mobile"
              fullWidth
              sx={{ mb: 4}}
              error={mobileError}
              helperText={
                mobileError ? "Please enter a valid mobile number" : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneAndroidIcon />
                  </InputAdornment>
                ),
                
              }}
            />
          </Stack>
          <TextField
                type="text"
                variant="outlined"
                // color="secondary"
                label="Employee Id"
                required
                fullWidth
                onChange={handleChange}
                value={employee_id}
                name="employee_id"
                sx={{ mb: 4}}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIndIcon />
                    </InputAdornment>
                  ),
                  
                }}
              />
          <TextField
          // autoFocus
            type={showPassword ? "text" : "password"}
            variant="outlined"
            // color="secondary"
            label="Password"
            required
            onChange={handleChange}
            value={password}
            name="password"
            fullWidth
            sx={{ mb: 4}}
            error={password && password.length > 0 && !userData.isValidPassword} // Highlight if password is not valid
            helperText={
              password && password.length > 0 && !userData.isValidPassword ? (
                <div>
                  Password must be at least 8 characters long
                  <br /> and contain a combination of:
                  <br />
                  - Uppercase and lowercase letters
                  <br />
                  - At least one numeric digit (0-9)
                  <br />- At least one special character
                </div>
              ) : null
            }
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            style={{ marginBottom: "30px" }}
            // autoFocus
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            required
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={!passwordsMatch}
            helperText={!passwordsMatch && "Passwords don't match"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="outlined"
            color="secondary"
            type="submit"
            style={{
              marginTop: "20px",
              display: "block",
              margin: "auto",
              backgroundColor: "rgb(0, 100, 255)",
              color: "white",
              fontWeight: "bold",
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} /> // Show loader when loading is true
            ) : (
              "Register"
            )}
          </Button>
        </form>
      </Box>

      {/* SignUp Dialog Box */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SignupPage;
