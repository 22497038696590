import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import axios from "axios";
import Navbar from "../../Components/Navbar/Navbar";
import Sidenav from "../../Components/Navbar/SideNav";
import style from "./home.module.css"
import Loader from "../../Components/LoaderComponent";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const HomePage = () => {
  const [totalUsers, setTotalUsers] = useState([]);
  const [activatedUsers, setActivatedUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTotalUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/users`);

      setTotalUsers(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getActivatedUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/users`);
      // console.log(res)
      const Activated_Users = res.data?.data?.filter(
        (user) => user.active === true
      );
      setActivatedUsers(Activated_Users);
    } catch (error) {
      console.log(error);
    }
  };

  const getPendingUsers = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/users`);
      const Pending_Users = res.data?.data?.filter(
        (user) => user.active === false
      );
      setPendingUsers(Pending_Users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Start loading
    setIsLoading(true);

    // Fetch data
    Promise.all([getTotalUsers(), getActivatedUsers(), getPendingUsers()])
      .then(() => {
        // Set loading to false when data is loaded
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // Handle errors and set loading to false
        setIsLoading(false);
      });
  }, []);

  // console.log("activatedUsers.length",activatedUsers.length)
  localStorage.setItem("totalUsers", totalUsers.length);
  localStorage.setItem("activatedUsers", activatedUsers.length);
  localStorage.setItem("pendingUsers", pendingUsers.length);

  const total_Users = parseInt(localStorage.getItem("totalUsers"), 10) || 0;
  const activeUsers = parseInt(localStorage.getItem("activatedUsers"), 10) || 0;
  const pending_Users = parseInt(localStorage.getItem("pendingUsers"), 10) || 0;

  // Data for the bar chart
  const chartData = [
    { name: "Total Users", count: total_Users, color: "#8884d8" }, // Blue
    { name: "Active Users", count: activeUsers, color: "#82ca9d" }, // Green
    { name: "Pending Users", count: pending_Users, color: "#ffc658" }, // Yellow
  ];

  return (
    <>
      <Navbar />
      <Grid container spacing={2}>
        <Sidenav />
        {isLoading ? (
          <Loader />
        ) : (
          <Grid className={style.totalUsersCard}>
            <Card sx={{ backgroundColor: "#238548" }}>
              <CardContent>
                <Typography variant="h6" sx={{ color: "white", fontWeight: "bold" }}>
                  Total Active Users
                </Typography>
                <Typography variant="h4" sx={{ color: "white", fontWeight: "bold" }}>
                  {activeUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default HomePage;
