import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../firebase";
import {
  Container,
  TextField,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import Navbar from "../Components/Navbar/Navbar";
import Sidenav from "../Components/Navbar/SideNav";
import ImageCompressor from 'image-compressor';
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import chatLogo from "../../src/assets/Capital_Job_Link/Images/chatLogo.jpg";
import Profile from "./Profile";
import SelectedUserNavOptions from "./SelectedUserNavOptions";
import Loader from "../Components/LoaderComponent";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {chatFolderName} from "../Config";
import {fileStorageFolder} from "../Config";

const BASE_URL = process.env.REACT_APP_BASE_URL;

function ChatComponent() {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastMessages, setLastMessages] = useState({});
  const [latestMessages, setLatestMessages] = useState({});
  const senderId = localStorage.getItem("id");
  const [allMessages, setAllMessages] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  // const [messageDate, setMessageDate] = useState("");
  const messageInputRef = useRef(null);
  // const [lastDisplayedDate, setLastDisplayedDate] = useState(null);
  const messagesContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [lastMessagesStatus,setLastMessagesStatus] = useState({});
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages]);

  const updateMessages = (newMessages) => {
    setMessages(newMessages);
  };

  // Fetching app users
  const getAppUsers = () => {
    axios
      .get(`${BASE_URL}/users`)
      .then((res) => {
        const filteredUsers = res?.data?.data.filter(
          (user) => user._id !== senderId
        );
        setUsers(filteredUsers);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Creating Chat Room
  const chatRoomId = (senderId, receiverId) => {
    return `${senderId}_${receiverId}`;
  };
  useEffect(() => {
    getAppUsers();
  }, [userStatus]);

  const sendMessage = () => {
    if ((message.trim() !== "" || file) && selectedUser) {
      const chatRoom = chatRoomId(senderId, selectedUser._id);
      const chatRef = db.ref(`${chatFolderName}/admin/Rooms/${chatRoom}/messages`);
      const id = Date.now();
      const date = new Date();
      const year = date.getFullYear();
      const month = date.toLocaleString("en-US", { month: "short" });
      const day = date.getDate();
      const formattedDate = `${month} ${day} ${year}`;

      // Create a message object with both text and file properties
      const messageData = {
        id: id,
        // text: message,
        senderId: senderId,
        receiverId: selectedUser._id,
        timestamp: id,
        status: "1",
        isChatDeleted: false,
        date: formattedDate,
        ...(file ? { url: null,name:file.name, type: file.type } : {type: "text", text: message }),
      };

      // Add the message to the database
      chatRef.child(id).set(messageData);

      // Upload the file to Firebase Storage if a file is selected
      if (file) {
        uploadFileToStorage(file, id, chatRef.child(id));
      }

      // Update the last message in the chat room
      const lastMessageRef = db.ref(`${chatFolderName}/admin/Rooms`);
      lastMessageRef.ref.child(chatRoom).child("last_message").set(messageData);

      setMessage("");
      setFile(null);
      setSelectedFileName("")
    }
  };

  const uploadFileToStorage = async (file, messageId, messageRef) => {
    try {
      const storageRef = storage.ref(`files/${fileStorageFolder}/${messageId}_${file.name}`);
      const snapshot = await storageRef.put(file);
      const url = await snapshot.ref.getDownloadURL();

      // Update the file URL in the database
      messageRef.update({ url: url });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };



  // Fetching Text Messages Logic
  // const fetchMessages = (chatRoomId) => {
  //   const chatRef = db.ref(`${chatFolderName}/admin/Rooms/${chatRoomId}/messages`);

  //   chatRef.on("child_added", (snapshot) => {
  //     const messageData = snapshot.val();
  //     setMessages((prevMessages) =>
  //       [...prevMessages, messageData].sort((a, b) => a.timestamp - b.timestamp)
  //     );
  //   });
  // };

  const fetchMessages = () => {
    users.forEach((user) => {
      const senderId = localStorage.getItem("id"); // Define senderId here
      const chatRoomId = `${senderId}_${user._id}`; // Define chatRoomId here
  
      const chatRef = db.ref(`${chatFolderName}/admin/Rooms/${chatRoomId}/messages`);
  
      chatRef.on("child_added", (snapshot) => {
        const messageData = snapshot.val();
        setMessages((prevMessages) => [...prevMessages, messageData].sort((a, b) => a.timestamp - b.timestamp));
  
        // Update the message status to '1' (received) if the admin is online
        if (userStatus === "online" && messageData.status === "0") {
          chatRef.child(snapshot.key).update({ status: "1" });
        }
      });
    });
  };
  

  useEffect(() => {
    if (selectedUser) {
      setMessages([]);
      // setLastDisplayedDate(null)
      localStorage.removeItem("selectedUserId");
      localStorage.setItem("selectedUserId", selectedUser._id);
      const chatRoom1 = chatRoomId(senderId, selectedUser._id);
      fetchMessages(chatRoom1);
      const chatRefSender = db.ref(`${chatFolderName}/admin/Rooms/${chatRoom1}/messages`);
      const chatRefLastMessage = db.ref(`${chatFolderName}/admin/Rooms/${chatRoom1}/last_message`);

      // Update message status to seen when user will read it.
      chatRefSender.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const messageData = snapshot.val();
          const messageArray = Object.values(messageData);
          messageArray.forEach((msg) => {
            if (msg.status === "1" && msg.senderId === selectedUser._id) {
              const messageKey = Object.keys(messageData).find(
                (key) => messageData[key] === msg
              );
              chatRefSender.child(messageKey).update({ status: "2" });
            }
          });
        }
      });
      // chatRefLastMessage.on("value", (snapshot) => {
      //   if (snapshot.exists()) {
      //     const messageData = snapshot.val();
      //     if (messageData.status === "1" && messageData.senderId === selectedUser._id) {
      //       chatRefLastMessage.update({ status: "2" });
      //     }
         
      //   }
      // });
    }
  }, [selectedUser, senderId, lastMessages]);

  function deliveredMessageStatus() {
    if (selectedUser) {
      const chatRoom1 = chatRoomId(senderId, selectedUser._id);
      const chatRefSender = db.ref(`${chatFolderName}/admin/Rooms/${chatRoom1}/messages`);
      const chatRefLastMessage = db.ref(`${chatFolderName}/admin/Rooms/${chatRoom1}/last_message`);
      const receiverId = selectedUser._id;

      const userOnlineStatus = userStatus.find(
        (status) => status.userId === receiverId
      )?.status;
      // console.log("userOnlineStatus",userOnlineStatus)
      // if (userOnlineStatus === "online") {
        chatRefSender.on("value", (snapshot) => {
          if (snapshot.exists()) {
            const messageData = snapshot.val();
            const messageArray = Object.values(messageData);
            // console.log("messageArray",messageArray)
            messageArray.forEach((msg) => {
              if (msg.status === "0" && msg.receiverId === receiverId) {
                const messageKey = Object.keys(messageData).find(
                  (key) => messageData[key] === msg
                );
                // console.log("messageKey",messageKey)
                chatRefSender.child(messageKey).update({ status: "1" });
              }
            });
          }
        });
        // chatRefLastMessage.on("value", (snapshot) => {
        //   if (snapshot.exists()) {
        //     const messageData = snapshot.val();
        //       if (messageData.status === "0" && messageData.senderId === receiverId) {
        //         chatRefLastMessage.update({ status: "1" });
        //       }

        //   }
        // });
      //  }
    }
  }
  useEffect(() => {
    deliveredMessageStatus();
  }, [userStatus]);

  // Last message logic
  const fetchLastMessages = () => {
    setLastMessages({});
    setLatestMessages({});
    setLastMessagesStatus({});
    const lastMessageRef = db.ref(`${chatFolderName}/admin/Rooms`);
    lastMessageRef.on("value", (snapshot) => {
      const lastMessagesData = snapshot.val();
      const updatedLastMessages = {};
      const updatedLatestMessages = {};
      const lastMessagesStatus = {};

      for (const chatRoomId in lastMessagesData) {
        const lastMessage = lastMessagesData[chatRoomId].last_message;
        if (chatRoomId.includes(senderId)) {
          const receiverId = chatRoomId.replace(senderId, "").replace("_", "");

          if (receiverId !== senderId) {
            updatedLastMessages[receiverId] = lastMessage?.text;
            lastMessagesStatus[receiverId] = lastMessage?.status;
            updatedLatestMessages[receiverId] = lastMessage?.timestamp;
          }
        }
      }

      setLastMessages(updatedLastMessages);
      setLatestMessages(updatedLatestMessages);
      setLastMessagesStatus(lastMessagesStatus);
    });
  };

  const truncateString = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  // Function to fetch messages for a specific chat room
  const fetchMessagesForChatRoom = (chatRoomId) => {
    const chatRef = db.ref(`${chatFolderName}/admin/Rooms/${chatRoomId}/messages`);
    chatRef.on("child_added", (snapshot) => {
      const messageData = snapshot.val();
      setAllMessages((prevMessages) => [...prevMessages, messageData]);
    });
  };



  // Function to fetch messages for all chat rooms
  const fetchAllMessages = () => {
    users.forEach((user) => {
      const chatRoom1 = chatRoomId(senderId, user._id);
      const chatRoom2 = chatRoomId(user._id, senderId);

      fetchMessagesForChatRoom(chatRoom1);
      fetchMessagesForChatRoom(chatRoom2);
    });
  };
  useEffect(() => {
    setAllMessages([]);
    fetchAllMessages();
  }, [users]);

  const filterUsersAndMessages = (user) => {
    const searchTermLowerCase = searchTerm?.toLowerCase();

    const userFullName = user?.fullName?.toLowerCase();
    if (userFullName.includes(searchTermLowerCase)) {
      return true;
    }

    const userMessages = allMessages?.filter(
      (msg) =>
        msg?.text?.toLowerCase().includes(searchTermLowerCase) &&
        (msg.receiverId === user._id || msg.senderId === user._id)
    );

    const lastMessage = lastMessages?.[user._id];
    if (
      lastMessage &&
      lastMessage.toLowerCase().includes(searchTermLowerCase)
    ) {
      return true;
    }

    // Check if any of the user's messages contain the search term
    if (userMessages?.length > 0) {
      return true;
    }

    return false;
  };

  // Sort the users array based on the latest message timestamp
  const sortedUsers = users.sort((userA, userB) => {
    const timestampA = latestMessages[userA._id];
    const timestampB = latestMessages[userB._id];

    if (timestampA && timestampB) {
      return timestampB - timestampA;
    } else if (timestampA) {
      return -1;
    } else if (timestampB) {
      return 1;
    }
    return 0;
  });

  // Updating User Status When user logout
  // useEffect(() => {
  //   if (senderId) {
  //     const userStatusRef = db.ref(
  //       `${chatFolderName}/admin/userStatus/${senderId}/userStatus`
  //     );
  //     const userIdRef = db.ref(`${chatFolderName}/admin/userStatus/${senderId}/userId`);

  //     userStatusRef.set("online");
  //     userIdRef.set(`${senderId}`);
  //     return () => {
  //       userStatusRef.set("offline");
  //     };
  //   }
  // }, [senderId]);

  // Function to update the user's status when user closes app
  // const updateUserStatus = (status) => {
  //   if (senderId) {
  //     const userStatusRef = db.ref(
  //       `${chatFolderName}/admin/userStatus/${senderId}/userStatus`
  //     );
  //     const userIdRef = db.ref(`${chatFolderName}/admin/userStatus/${senderId}/userId`);
  //     userStatusRef.set(status);
  //     userIdRef.set(`${senderId}`);
  //   }
  // };

  // Function to handle app start
  // const handleAppStart = () => {
  //   updateUserStatus("online");
  //   window.addEventListener("beforeunload", () => {
  //     updateUserStatus("offline");
  //   });
  //   return () => {
  //     window.removeEventListener("beforeunload", () => {});
  //   };
  // };

  // Fetching User Status
  const fetchUserStatus = () => {
    const AllStatus = [];

    users.forEach((user) => {
      const userStatusRef = db.ref(
        `${chatFolderName}/admin/userStatus/${user._id}/userStatus`
      );
      userStatusRef.on("value", (snapshot) => {
        if (snapshot.exists()) {
          const userStatus = snapshot.val();
          AllStatus.push({ userId: user._id, status: userStatus });
        }
      });
    });

    return AllStatus;
  };

  useEffect(() => {
    const allStatus = fetchUserStatus();
    setUserStatus(allStatus);
  }, [users]);

  useEffect(() => {
    // handleAppStart();
    // getAppUsers();
    fetchLastMessages();
  }, []);

  
  const downloadImage = (file) => {
    console.log("file",file)
    const blob = new Blob([file.data], { type: file.type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileChange = (e)=>{
    const selectedFile = e.target.files[0];
  setFile(selectedFile);
  setSelectedFileName(selectedFile.name);
  }
  // console.log("userStatus", userStatus);
  return (
    <>
      <Navbar />
      <Sidenav />
      <div style={{ width: "87%", marginLeft: "170px", marginTop: "90px" }}>
        <Container>
          <Grid container>
            <Grid item sm={4} md={3}>
              {isLoading ? (
                <Loader />
              ) : (
                <Paper
                  style={{
                    padding: "20px",
                    marginTop: "0px",
                    height: "75vh",
                    overflowY: "hidden",
                    borderRight: "none",
                  }}
                >
                  {/* New Group */}
                  <Profile />
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label="Search Users"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">🔍</InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                  <div
                    style={{
                      maxHeight: "calc(70vh - 90px)",
                      overflowY: "auto",
                    }}
                  >
                    <List>
                      {sortedUsers
                        .filter((user) => filterUsersAndMessages(user))
                        .map((user) => (
                          <ListItem
                            button
                            key={user._id}
                            onClick={() => setSelectedUser(user)}
                            selected={
                              selectedUser && selectedUser._id === user._id
                            }
                            style={{
                              borderBottom: "1px solid #ccc",
                              borderRadius: "5px",
                              marginBottom: "3px",
                            }}
                          >
                            {/* User status */}
                            <div
                              style={{
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor:
                                  userStatus.find(
                                    (status) => status.userId === user._id
                                  )?.status === "online"
                                    ? "green"
                                    : "#f7a116",
                                marginRight: 8,
                              }}
                            ></div>
                            <ListItemText
                              primary={user.fullName}
                              secondary={truncateString(
                                lastMessages[user._id],
                                20
                              )}
                              primaryTypographyProps={{
                                style: {
                                  fontWeight:
                                   lastMessagesStatus[user._id] === "0"
                                      ? "bold"
                                      : "normal",
                                },
                              }}
                              secondaryTypographyProps={{
                                style: {
                                  fontWeight:
                                   lastMessagesStatus[user._id] === "0"
                                      ? "bold"
                                      : "normal",
                    
                                  color:
                                   lastMessagesStatus[user._id] === "0"
                                      ? "black"
                                      : "",
                                      
                                },
                                
                              }}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </Paper>
              )}
            </Grid>
            <Grid item xs={8}>
              <Paper
                elevation={3}
                style={{
                  padding: "20px",
                  marginTop: "0px",
                  height: "75vh",
                  // overflowY: "scroll",
                  borderLeft: "none",
                  width: "100%",
                }}
              >
                {selectedUser ? (
                  <div>
                    <div
                      style={{
                        backgroundColor: "#e0dcdc",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    >
                      <h4>{selectedUser?.fullName}</h4>
                      <SelectedUserNavOptions updateMessages={updateMessages} />
                    </div>

                    <div
                      style={{
                        height: "calc(71vh - 90px)",
                        overflowY: "auto",
                      }}
                    >
                      {messages?.map((msg, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign:
                              msg.senderId === senderId ? "right" : "left",
                            marginBottom: "8px",
                          }}
                          ref={messagesContainerRef}
                        >
                          {msg && msg.isChatDeleted !== true && 
                          (
                            <div>
                              <div
                            style={{
                              fontSize: "10px",
                              color:
                                msg.senderId === senderId ? "black" : "black",
                              marginTop: "5px",
                            }}
                          >
                            {msg?.date}
                          </div>
                          <div
                            style={{
                              background:
                                msg.senderId === senderId
                                  ? "#DCF8C6"
                                  : "#e0dcdc",
                              borderRadius: "10px",
                              padding: "10px",
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {(msg.type==="text")? (
                              <Typography variant="body1">
                                {msg.text}
                              </Typography>
                            ):(
                              <div>
                              {msg?.type &&
                              msg?.type.startsWith("image") ? (
                                <div>
                                  <p>{msg?.name}</p>
                                  <img
                                    src={
                                      msg?.url
                                        ? msg?.url
                                        : `data:${msg?.type};base64,${msg.data}`
                                    }
                                    alt={msg?.name}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "200px",
                                    }}
                                  />
                                  {msg?.url && (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => downloadImage(msg)}
                                    >
                                      <CloudDownloadIcon />
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <p>{msg?.name}</p>
                                  <embed
                                    src={
                                      msg?.url
                                        ? msg?.url
                                        : `data:${msg?.type};base64,${msg.data}`
                                    }
                                    type={msg?.type}
                                    width="100%"
                                    height="200px"
                                  />
                                  {msg?.url && (
                                    <a
                                      href={
                                        msg?.url
                                          ? msg?.url
                                          : `data:${msg.type};base64,${msg.data}`
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      download={msg?.name}
                                    >
                                      <CloudDownloadIcon />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                            )
                          }
                           
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  fontSize: "10px",
                                  color:
                                    msg.senderId === senderId
                                      ? "black"
                                      : "black",
                                  marginTop: "3px",
                                }}
                              >
                                {new Intl.DateTimeFormat("en-US", {
                                  hour: "numeric",
                                  minute: "numeric",
                                }).format(msg.timestamp)}
                              </div>

                              {msg.senderId === senderId && (
                                <span
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "10px",
                                    color: msg.status === "2" ? "blue" : "gray",
                                    marginTop: "3px",
                                  }}
                                >
                                  {msg.status === "1"
                                    ? "✓✓"
                                    : msg.status === "2"
                                    ? "✓✓"
                                    : "✓"}{" "}
                                </span>
                              )}
                            </div>
                          </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        // position: "absolute",
                        // bottom: "1vw",
                        display: "flex",
                        // marginTop:".2vw",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <TextField
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        label="Enter Message"
                        value={message || selectedFileName}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            sendMessage();
                          }
                        }}
                        ref={messageInputRef}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <label htmlFor="file-input">
                                <IconButton component="span" color="primary">
                                  <AttachFileIcon />
                                </IconButton>
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                accept="image/*, .pdf, .doc, .docx, .png, .jpeg, .jpg"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(e)}
                              />
                            </InputAdornment>
                          ),
                        }}
                        
                      />

                      <IconButton color="primary" onClick={sendMessage}>
                        <SendIcon />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <img
                    src={chatLogo}
                    alt="logo"
                    style={{ width: "100%", height: "calc(80vh - 74px)" }}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default ChatComponent;