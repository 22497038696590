import React, { useEffect, useState } from "react";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import CallIcon from "@mui/icons-material/Call";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Menu, MenuItem } from "@mui/material";
import axios from "axios";
import Popover from "@mui/material/Popover";
import { db } from "../firebase";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SelectedUserNavOptions = ({ updateMessages }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [user, setUser] = useState({});
  const [contactInfoOpen, setContactInfoOpen] = useState(false);
  const [deleteChatLoading, setDeleteChatLoading] = useState(false);
  const [contactInfoLoading, setContactInfoLoading] = useState(false);
  const [blockUserLoading, setBlockUserLoading] = useState(false);
  const [blockUser, setBlockUser] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");


  const handleMoreVertClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreVertClose = () => {
    setAnchorEl(null);
  };

  // Contact Info Logic
  const handleContactInfoClick = () => {
    const id = localStorage.getItem("selectedUserId");
    setContactInfoLoading(true); 
    axios
      .get(`${BASE_URL}/getProfile/${id}`)
      .then((res) => {
        setUserDetails(res?.data?.data);
        setContactInfoOpen(true);
        handleMoreVertClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setContactInfoLoading(false); 
      });
  };

  const handleContactInfoClose = () => {
    setContactInfoOpen(false);
  };

  const handleDeleteChatClick = async () => {
    const selectedUserId = localStorage.getItem("selectedUserId");
    const senderId = localStorage.getItem("id");
  
    // Reference to the chat room
    const chatRoomIdSender = `${senderId}_${selectedUserId}`;
    const chatRoomIdReceiver = `${selectedUserId}_${senderId}`;
  
    // References to messages and last message for both sender and receiver
    const chatRefSenderMessages = db.ref(`chats/admin/Rooms/${chatRoomIdSender}/messages`);
    const chatRefSenderLastMessage = db.ref(`chats/admin/Rooms/${chatRoomIdSender}/last_message`);
    const chatRefReceiverMessages = db.ref(`chats/admin/Rooms/${chatRoomIdReceiver}/messages`);
    const chatRefReceiverLastMessage = db.ref(`chats/admin/Rooms/${chatRoomIdReceiver}/last_message`);
  
    setDeleteChatLoading(true); 
  
    try {
      // Fetch and update all messages for the sender
      const senderMessagesSnapshot = await chatRefSenderMessages.once("value");
      senderMessagesSnapshot.forEach((snapshot) => {
        const messageId = snapshot.key;
        chatRefSenderMessages.child(messageId).update({ isChatDeleted: true });
      });
  
      // Update the last message for the sender
      // chatRefSenderLastMessage.update({ isChatDeleted: true });
  
      // Fetch and update all messages for the receiver
      const receiverMessagesSnapshot = await chatRefReceiverMessages.once("value");
      receiverMessagesSnapshot.forEach((snapshot) => {
        const messageId = snapshot.key;
        chatRefReceiverMessages.child(messageId).update({ isChatDeleted: true });
      });
  
      // Update the last message for the receiver
      // chatRefReceiverLastMessage.update({ isChatDeleted: true });
  
      // // Remove the messages and last messages
      // await chatRefSenderMessages.remove();
      await chatRefSenderLastMessage.remove();
      // await chatRefReceiverMessages.remove();
      await chatRefReceiverLastMessage.remove();
  
      updateMessages([]);
      handleMoreVertClose();
      console.log("Chat messages deleted successfully.");
    } catch (error) {
      console.error("Error deleting chat messages:", error);
    } finally {
      setDeleteChatLoading(false); 
    }
  };
  
  const id = localStorage.getItem("selectedUserId");
  const adminId  = localStorage.getItem("id");

  // Block user in api
  const handleBlockUser = () => {
    setBlockUserLoading(true);
    axios
      .put(`${BASE_URL}/blockUser/${id}`,{ adminId })
      .then((res) => {
        // console.log("Res", res);
        // Additional success handling code
        setDialogMessage(res?.data?.message);
        setDialogOpen(true);
        setTimeout(() => {
          setDialogOpen(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        // Additional error handling code
      })
      .finally(() => {
        setBlockUserLoading(false);
        // Additional code to execute regardless of success or error
      });
  };

  // Block user in firebase
  // const handleBlockUser = async () => {
  //   setBlockUserLoading(true);
  //   const selectedUserId = localStorage.getItem("selectedUserId");
  //   const senderId = localStorage.getItem("id");
  
  //   // Reference to the chat room
  //   const chatRoomIdSender = `${senderId}_${selectedUserId}`;
  //   const chatRoomIdReceiver = `${selectedUserId}_${senderId}`;
  
  //   // References to messages and last message for both sender and receiver
  //   const chatRefSenderMessages = db.ref(`chats/admin/Rooms/${chatRoomIdSender}/messages`);
  //   const chatRefSenderLastMessage = db.ref(`chats/admin/Rooms/${chatRoomIdSender}/last_message`);
  //   const chatRefReceiverMessages = db.ref(`chats/admin/Rooms/${chatRoomIdReceiver}/messages`);
  //   const chatRefReceiverLastMessage = db.ref(`chats/admin/Rooms/${chatRoomIdReceiver}/last_message`);
  
  //   try {
  //     // Fetch and update all messages for the sender
  //     const senderMessagesSnapshot = await chatRefSenderMessages.once("value");
  //     senderMessagesSnapshot.forEach((snapshot) => {
  //       const messageId = snapshot.key;
  //       const isBlocked = snapshot.val().isBlocked || false;
  //       setBlockUser(isBlocked)
  //       chatRefSenderMessages.child(messageId).update({ isBlocked: !isBlocked });
  //     })
  
  //     // Fetch and update all messages for the receiver
  //     const receiverMessagesSnapshot = await chatRefReceiverMessages.once("value");
  //     receiverMessagesSnapshot.forEach((snapshot) => {
  //       const messageId = snapshot.key;
  //       const isBlocked = snapshot.val().isBlocked || false; 
  //       chatRefReceiverMessages.child(messageId).update({ isBlocked: !isBlocked });
  //     });
  //     setDialogOpen(true);
  //     setTimeout(() => {
  //       setDialogOpen(false);
  //     }, 2000);
  //   } catch (error) {
  //     console.error("Error blocking user:", error);
  //   } finally {
  //     setBlockUserLoading(false);
  //   }
  // };
  

  useEffect(()=>{
    axios
    .get(`${BASE_URL}/getProfile/${id}`)
    .then((res) => {
      setUser(res?.data?.data);
      
    })
    .catch((error) => {
      console.log(error);
    })
  },[user])
  // console.log("user",user)
  return (
    <div>
      <div>
        {/* <IconButton color="primary">
          <VideoCallIcon />
        </IconButton>
        <IconButton color="primary">
          <CallIcon />
        </IconButton> */}
        <IconButton
          color="primary"
          onClick={handleMoreVertClick}
          aria-controls="more-vert-menu"
          aria-haspopup="true"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="more-vert-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMoreVertClose}
          style={{ marginLeft: "-80px" }}
        >
          <MenuItem onClick={handleContactInfoClick}>
            {contactInfoLoading ? "Loading..." : "Contact Info"}
          </MenuItem>
          <MenuItem onClick={handleDeleteChatClick}>
            {deleteChatLoading ? "Clearing..." : "Clear Chat"}
          </MenuItem>
          <MenuItem onClick={handleBlockUser}>
            {blockUserLoading
              ? "Blocking..."
              : user?.isBlocked
              ? "Unblock User"
              : "Block User"}
          </MenuItem>
        </Menu>
      </div>
      <Popover
        open={contactInfoOpen}
        anchorEl={anchorEl}
        onClose={handleContactInfoClose}
        style={{marginTop: "12vw", marginLeft: "72vw" }}
      >
        <div style={{ padding: "16px" }}>
          <IconButton
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={handleContactInfoClose}
          >
            <CloseIcon />
          </IconButton>
          <div>Name: {userDetails?.fullName}</div>
          <div>Email: {userDetails?.email}</div>
          <div>Mobile Number: {userDetails?.mobile}</div>
        </div>
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto", // Adjust the width as needed
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "50px", // Set a minimum height to center vertically
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectedUserNavOptions;
