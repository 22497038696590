import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Stack,
  DialogContentText,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import {
  // Import icons from Material-UI Icons
  Title as TitleIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import style from "./notificationForm.module.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SignupPage = ({ onClose }) => {
  const [notificationType, setNotificationType] = useState("General");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [targetUsers, setTargetUsers] = useState("");
  const [requestValue, setRequestValue] = useState(true);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSpecificUsersSelect, setShowSpecificUsersSelect] = useState(false);
  const [selectedSpecificUsers, setSelectedSpecificUsers] = useState([]);
  const [appUsers, setAppUsers] = useState([]);
  const [selectedButton, setSelectedButton] = useState("General");

  const getAppUsers = () => {
    axios
      .get(`${BASE_URL}/users`)
      .then((res) => {
        setAppUsers(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAppUsers();
  }, []);
  // Extracting Specific users id's
  let specificUsersIds = [];
  specificUsersIds = specificUsersIds.concat(
    selectedSpecificUsers?.map((user) => user._id)
  );
  // console.log("specificUsersIds", specificUsersIds);

  // Sending Notification
  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (targetUsers === "specificUsers") {
      axios
        .post(`${BASE_URL}/send_notification`, {
          notificationType: notificationType,
          notificationTitle: notificationTitle,
          notificationBody: notificationBody,
          targetUsers: specificUsersIds,
          requestValue: requestValue,
        })
        .then((res) => {
          console.log(res);
          setDialogMessage(res?.data?.message);
          setDialogOpen(true);
          setTimeout(() => {
            setDialogOpen(false);
            onClose();
          }, 3000);

          navigate("/notifications");
          setNotificationType("General");
          setNotificationTitle("");
          setNotificationBody("");
          setRequestValue(true);
          setTargetUsers("");
        })
        .catch((error) => {
          console.log("error", error);
          setDialogMessage(error?.response?.data?.message);
          setDialogOpen(true);
          setTimeout(() => {
            setDialogOpen(false);
          }, 3000);
        })
        .finally(() => {
          setLoading(false);
          // setNotificationType("General");
          // setNotificationTitle("");
          // setNotificationBody("");
          // setRequestValue(true);
          // setTargetUsers("");
        });
    } else {
      axios
        .post(`${BASE_URL}/send_notification`, {
          notificationType: notificationType,
          notificationTitle: notificationTitle,
          notificationBody: notificationBody,
          targetUsers: targetUsers,
          requestValue: requestValue,
        })
        .then((res) => {
          console.log(res);
          setDialogMessage(res?.data?.message);
          setDialogOpen(true);
          setTimeout(() => {
            setDialogOpen(false);
            onClose();
          }, 3000);

          navigate("/notifications");
        })
        .catch((error) => {
          console.log("error", error);
          setDialogMessage(error?.response?.data?.message);
          setDialogOpen(true);
          setTimeout(() => {
            setDialogOpen(false);
          }, 3000);
        })
        .finally(() => {
          setLoading(false);
          setNotificationType("General");
          setNotificationTitle("");
          setNotificationBody("");
          setRequestValue(true);
          setTargetUsers("");
        });
    }
  }

  const handleCancel = () => {
    onClose();
    setNotificationType("General");
    setNotificationTitle("");
    setNotificationBody("");
    setRequestValue(true);
    setTargetUsers("");
  };
  console.log("selectedButton", selectedButton);
  return (
    <React.Fragment>
      <Box style={{ width: "100%", display: "flex" }}>
        <form
          sx={{ overflow: "scroll" }}
          // className={style.Signup}
          onSubmit={handleSubmit}
          //   action={<Link to="/login" />}
        >
          <ToggleButtonGroup
            color="primary"
            value={selectedButton}
            exclusive
            onChange={(e, value) => {
              if (value === null) {
                // If value is null, set it to the currently selected button
                value = selectedButton;
              }
              setNotificationType(value); // Update notification type
              setSelectedButton(value); // Update selected button
            }}
            aria-label="Platform"
            sx={{ width: "100%" }}
          >
            <ToggleButton
              sx={{
                backgroundColor:
                  selectedButton === "General" ? "rgb(0, 81, 255)" : "white",
                width: "50%",
                color: selectedButton === "General" ? "white" : "black",
                border:
                  selectedButton === "General"
                    ? "2px solid rgb(0, 81, 255)"
                    : "1px solid grey",
              }}
              value="General"
            >
              General
            </ToggleButton>
            <ToggleButton
              sx={{
                width: "50%",
                backgroundColor:
                  selectedButton === "Shift" ? "rgb(0, 81, 255)" : "white",
                color: selectedButton === "Shift" ? "white" : "black",
                border:
                  selectedButton === "Shift"
                    ? "2px solid rgb(0, 81, 255)"
                    : "1px solid grey",
              }}
              value="Shift"
            >
              Shift
            </ToggleButton>
          </ToggleButtonGroup>

          <TextField
            autoFocus
            type="text"
            variant="outlined"
            // color="secondary"
            label="Title"
            onChange={(e) => setNotificationTitle(e.target.value)}
            fullWidth
            required
            sx={{
              marginTop: "1.5vw",
              marginBottom: "2vw",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TitleIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            type="text"
            variant="outlined"
            label="Description"
            onChange={(e) => setNotificationBody(e.target.value)}
            fullWidth
            required
            multiline
            rows={2}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" spacing={2} sx={{ marginTop: "2.5vw" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Target Users
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setTargetUsers(selectedValue);
                  setShowSpecificUsersSelect(selectedValue === "specificUsers");
                  setSelectedSpecificUsers([]);
                }}
                required
                fullWidth
                sx={{ width: "100%" }}
                label="Target Users"
              >
                <MenuItem value="allUsers">All Users</MenuItem>
                <MenuItem value="availableUsers">Available Users</MenuItem>
                <MenuItem value="verifiedUsers">Verified Users</MenuItem>
                <MenuItem value="specificUsers">Specific Users</MenuItem>
              </Select>
            </FormControl>

            {/* Conditionally render the second Select based on showSpecificUsersSelect */}
            {showSpecificUsersSelect && (
              <Autocomplete
                multiple
                id="specific-users"
                options={appUsers}
                getOptionLabel={(user) => user.fullName} // Display user names
                getOptionValue={(user) => user._id} // Use user IDs as values
                value={selectedSpecificUsers}
                onChange={(event, newValue) => {
                  setSelectedSpecificUsers(newValue);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Specific Users"
                    variant="outlined"
                  />
                )}
              />
            )}
          </Stack>
          <RadioGroup
            className={style.request}
            aria-label="request"
            name="request"
            value={requestValue}
            onChange={(e) => setRequestValue(e.target.value)}
          >
            <Stack direction="row" spacing={8}>
              <DialogTitle>Request Response</DialogTitle>
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </Stack>
          </RadioGroup>
          <Stack
            style={{ marginTop: "80px", marginBottom: "40px" }}
            direction="row"
            spacing={8}
          >
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              className={style.cancelButton}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              type="submit"
              className={style.sendButton}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} /> // Show loader when loading is true
              ) : (
                "Send Notification"
              )}
            </Button>
          </Stack>
        </form>
      </Box>

      {/* SignUp Dialog Box */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "auto",
            margin: "auto",
            marginTop: "2%",
          },
        }}
      >
        {/* <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SignupPage;
